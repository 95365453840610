import { Contract } from '../../../shared/models/contract';
import { Factory } from '../../../shared/factory';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ContractFactory extends Factory<Contract> {
    constructor() {
        super(Contract);
    }

}
