<h2
    mat-dialog-title
    translate
>
    LOGIN.SELECT_ROLE_DIALOG_TITLE
</h2>
<div class="modal-close">
    <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="dialogRef.close()"
    ></button>
</div>
<mat-dialog-content>
    <mrc-form-item-field
        [id]="'roleSelection'"
        [label]="'LOGIN.SELECT_ROLE_DIALOG_TITLE' | translate"
    >
        <ng-select
            [(ngModel)]="selectedRole"
            [clearable]="false"
            [items]="data.roles"
            [virtualScroll]="true"
            appendTo="body"
            bindLabel="selectLabel"
            cdkFocusInitial
            class="form-control form-element"
            labelForId="roleSelection"
        >
            <ng-template
                let-item="item"
                ng-option-tmp
            >
                <span
                    class="ng-value-label"
                    [title]="item.roleable_name + ' - ' + item.label"
                    >{{ item.roleable_name }} - {{ item.label }}
                </span>
            </ng-template>
        </ng-select>
    </mrc-form-item-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        type="button"
        class="btn btn-primary w-100"
        (click)="submit()"
        translate
    >
        LOGIN.LOGIN_AS
    </button>
</mat-dialog-actions>
