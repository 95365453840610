import { NgModule } from '@angular/core';
import { AuthModule, AUTH_SERVICE } from 'ngx-auth';

import { ProfileService } from '../profile/profile.service';
import { AuthenticationService } from './auth.service';

export function factory(
    authenticationService: AuthenticationService
): AuthenticationService {
    return authenticationService;
}

@NgModule({
    imports: [AuthModule],
    providers: [
        AuthenticationService,
        {
            provide: AUTH_SERVICE,
            deps: [AuthenticationService],
            useFactory: factory,
        },
        ProfileService,
    ],
})
export class AuthenticationModule {}
