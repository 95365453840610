<div class="container mt-3">
    <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
            <div class="card">
                <div class="card-body text-center">
                    <h1 translate>GENERIC.LOGOUT.AUTOMATIC_LOGOUT</h1>
                    <div
                        class="row mt-5"
                        *ngIf="module"
                    >
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-testid="enable-manual-input"
                            (click)="navigateToLogin()"
                            translate
                        >
                            LOGIN.RELOGIN
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
