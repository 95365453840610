import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUtilities } from '@core/forms/form-utilities';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { LogoService } from '@core/services/logo/logo.service';
import { TypedFormGroup } from '@core/types/form.type';
import { CustomPasswordValidators } from '@core/validators/custom-password.validators';
import { ResetPassword } from '@public/interfaces/reset-password.interface';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mrc-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    public passwordChanged = false;

    public resetPasswordForm: FormGroup<TypedFormGroup<ResetPassword>> =
        new FormGroup({
            email: new FormControl('', [Validators.required]),
            token: new FormControl('', [Validators.required]),
            password: new FormControl('', CustomPasswordValidators),
            password_confirmation: new FormControl('', Validators.required),
        });

    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        public logoService: LogoService,
        private toasterService: ToastrService,
        private formUtilities: FormUtilities,
        private router: Router,
        private translateService: TranslateService,
    ) {}

    public ngOnInit(): void {
        this.resetPasswordForm.patchValue(
            this.activatedRoute.snapshot.queryParams,
        );
    }

    public resetPassword(): void {
        this.resetPasswordForm.updateValueAndValidity();
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.resetPasswordForm.disable();
        const data = this.resetPasswordForm.getRawValue();
        this.http
            .post(`password/reset`, data)
            .pipe(
                catchError((error) => {
                    this.resetPasswordForm.enable();
                    if (error.status === 401) {
                        this.router.navigateByUrl(`/recovery-password`);
                        this.toasterService.error(error.error);
                    }

                    if (error.status === 422) {
                        switch (error.error.exceptionCode) {
                            case 'validationFailed':
                                if (
                                    error.error.payload.password?.length &&
                                    error.error.payload.password[0] ===
                                        'validation.new_password'
                                ) {
                                    this.formUtilities.setFormErrors(
                                        this.resetPasswordForm,
                                        {
                                            password:
                                                this.translateService.instant(
                                                    'ERROR.PASSWORD_ALREADY_USED',
                                                ),
                                        },
                                    );
                                } else {
                                    this.formUtilities.setFormErrors(
                                        this.resetPasswordForm,
                                        error.error.payload,
                                    );
                                }
                                break;
                            default:
                                this.formUtilities.setFormErrors(
                                    this.resetPasswordForm,
                                    error.error.payload,
                                );
                        }
                    }
                    return throwError(() => error);
                }),
            )
            .subscribe((response: ResponseGeneric<{ redirect_to: string }>) => {
                this.passwordChanged = true;
                this.toasterService.success(
                    this.translateService.instant(
                        'SUCCESS.PASSWORD_HAS_BEEN_CHANGED',
                    ),
                );
                this.router.navigateByUrl(
                    `${response.payload.redirect_to}/login`,
                );
            });
    }
}
