import { environment } from './environments/environment';
import { SystemAdminAppModule } from './system-admin-app/app.module';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(SystemAdminAppModule)
    .catch((err) => console.error(err));
