<ul class="list-group">
    @for (file of files; track file; let idx = $index) {
        <li class="list-group-item">
            <span class="list-group-item__title me-2">{{ file.name }}</span>
            <button
                type="button"
                class="btn btn-light"
                (click)="handleFileDelete.emit(idx)"
                [attr.aria-label]="'GENERIC.ACTIONS.DELETE_FILE' | translate"
            >
                <i
                    aria-hidden="true"
                    class="fal fa-trash-alt"
                ></i>
            </button>
        </li>
    }
</ul>
