import { Component, Input } from '@angular/core';
import { Status, StatusTypes } from '@models/status';

@Component({
    selector: 'mrc-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
    @Input() public status: Status;
    @Input() public statusIcons = true;
    @Input() public btnBorder = false;
    @Input() public showLabel = true;

    public styleClass: string;
    public type = 'default';

    public icoClass: string;
    public currentStatus: Status;

    public borderClass(): string {
        if (this.btnBorder) {
            if (!this.styleClass || this.status !== this.currentStatus) {
                this.currentStatus = this.status;
                this.type = this.status.type;
                if (this.status.type !== 'default') {
                    this.styleClass = `btn btn-ico btn-outline-${this.status.type}`;
                } else {
                    this.styleClass = `btn btn-ico btn-outline-secondary`;
                }
            }
            return this.styleClass;
        }
        return null;
    }

    public get classIcon(): string {
        switch (this.status.type) {
            case StatusTypes.danger:
                return 'hourglass';
            case StatusTypes.default:
                return 'circle';
            case StatusTypes.info:
                return 'circle';
            case StatusTypes.success:
                return 'check';
            case StatusTypes.warning:
                return 'check';
            case StatusTypes.red:
                return 'circle';
            default:
                return 'circle';
        }
    }
}
