<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img
                        [src]="logoService.loginLogo"
                        alt=""
                    />
                </div>

                <div class="card">
                    <div>
                        <div class="alert alert-info d-flex">
                            <div>
                                {{ 'EXTERNAL_LOGIN.LOG_IN_ON' | translate }}
                                <strong>{{ 'EXTERNAL_LOGIN.BENEFIT_PORTAL' | translate }}</strong
                                >{{
                                    'EXTERNAL_LOGIN.TO_AUTHORIZE' | translate
                                }}
                                <strong>{{
                                    loginVerification.client.name
                                }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <h1 class="title">
                            {{ 'EXTERNAL_LOGIN.LOG_IN' | translate }}
                        </h1>
                        <div class="row justify-content-center">
                            <p class="col-sm-8"></p>
                        </div>
                        <div class="row justify-content-center">
                            <div
                                class="col-sm-8 text-danger"
                                *ngIf="errorMessage"
                                [innerHtml]="errorMessage"
                            ></div>
                            <form
                                class="col-sm-8"
                                novalidate
                                (ngSubmit)="login()"
                                [formGroup]="loginForm"
                            >
                                <div class="form-control-holder form-group">
                                    <input
                                        type="email"
                                        class="form-control"
                                        [placeholder]="
                                            'EXTERNAL_LOGIN.EMAIL' | translate
                                        "
                                        name="email"
                                        formControlName="email"
                                        maxlength="180"
                                        email
                                    />
                                    <mrc-validation-errors
                                        [invalid]="
                                            loginForm.get('email').invalid
                                        "
                                        [dirty]="loginForm.get('email').dirty"
                                        [errors]="loginForm.get('email').errors"
                                    ></mrc-validation-errors>
                                </div>
                                <div class="form-control-holder form-group">
                                    <input
                                        type="password"
                                        class="form-control"
                                        [placeholder]="
                                            'EXTERNAL_LOGIN.PASSWORD'
                                                | translate
                                        "
                                        formControlName="password"
                                        name="password"
                                        maxlength="60"
                                    />
                                    <mrc-validation-errors
                                        [invalid]="
                                            loginForm.get('password').invalid
                                        "
                                        [dirty]="
                                            loginForm.get('password').dirty
                                        "
                                        [errors]="
                                            loginForm.get('password').errors
                                        "
                                    ></mrc-validation-errors>
                                </div>
                                <div class="form-group-btn">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        [disabled]="submitProcess"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fal fa-sync-alt"
                                            *ngIf="submitProcess"
                                        >
                                        </i>
                                        <span>{{
                                            'EXTERNAL_LOGIN.REGISTER'
                                                | translate
                                        }}</span>
                                    </button>
                                </div>
                                <div class="links text-center">
                                    <a
                                        [routerLink]="
                                            '../password/forgot-password'
                                        "
                                        class="link-inherit"
                                        >{{
                                            'EXTERNAL_LOGIN.PASSWORD_FORGOTTEN'
                                                | translate
                                        }}</a
                                    >
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
