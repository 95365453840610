<div>
    <div
        [class]="'form-group ' + layoutClasses"
        [ngClass]="{
            'd-flex': contentType === FormItemFieldContentType.matCheckbox,
            'justify-content-end flex-row-reverse':
                contentType === FormItemFieldContentType.matCheckbox && reversed
        }"
    >
        <div class="d-flex justify-content-between align-items-center">
            <label
                *ngIf="label"
                [ngClass]="{ 'out-card': isOutCard, pointer: control?.enabled }"
                [for]="contentBasedID"
            >
                <span [innerHtml]="label"></span>
                <span
                    *ngIf="isRequired()"
                    class="field-star"
                    aria-hidden="true"
                    >*</span
                >
            </label>
            <i
                *ngIf="tooltipText"
                class="fal fa-info-circle fs-6"
                [attr.aria-label]="tooltipText"
                [matTooltip]="tooltipText"
            ></i>
        </div>

        <div class="form-control-holder">
            <ng-content></ng-content>
        </div>
    </div>
    <mrc-validation-errors
        [dirty]="control?.dirty"
        [invalid]="control?.invalid"
        [errors]="control?.errors"
    ></mrc-validation-errors>
</div>
