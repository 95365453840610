import { Component, OnInit } from '@angular/core';
import { AdminSettings } from '@shared/models/admin-settings';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';
import { MaintenanceService } from './maintenance.service';

@Component({
    selector: 'mrc-maintenance',
    templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit {
    public settings$: Observable<AdminSettings>;

    constructor(
        private maintenanceService: MaintenanceService,
        private toasterService: ToastrService
    ) {}

    public ngOnInit(): void {
        this.settings$ = this.maintenanceService.getSettings().pipe(
            catchError((error) => {
                this.toasterService.error(error);
                return throwError(() => error);
            })
        );
    }
}
