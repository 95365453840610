import { RelationType } from '@core/relations/relation';
import { Model } from './models/model';

export class Factory<T> {
    constructor(public type: new () => T) {}

    public fromResponse(bodyResponse: any[] | any): T | T[] {
        if (Array.isArray(bodyResponse)) {
            return this.fromArray(bodyResponse);
        }
        return this.fromObject(bodyResponse);
    }

    public create(): T {
        const instance = new this.type();
        if (this.applyRelations) {
            this.applyRelations(instance);
        }
        return instance;
    }

    public copy(object: T): T {
        const created = this.create();
        return Object.assign(created, object);
    }

    public fromArray(payload: any[]): T[] {
        if (!payload) {
            return [];
        }
        return payload.map((item) => this.fromObject(item));
    }

    public fromObject(object: any): T {
        const instantiation = Object.assign(new this.type(), object);
        if (this.applyRelations) {
            this.applyRelations(instantiation);
        }
        // this means it is stored in the database. Field is private and only accessible through isStored()
        instantiation['stored'] = true;
        return instantiation;
    }

    private applyRelations(instance: T): void {
        const relations = Model.relations.get(<any>this.type);
        if (relations && relations.length > 0) {
            Model.relations.get(<any>this.type).forEach((relation) => {
                const factory = new Factory(relation.classType);
                if (instance[relation.field]) {
                    if (relation.type === RelationType.HasOne) {
                        instance[relation.field] = factory.fromObject(
                            instance[relation.field],
                        );
                    } else if (relation.type === RelationType.HasMany) {
                        instance[relation.field] = factory.fromArray(
                            instance[relation.field],
                        );
                    }
                } else if (
                    !instance[relation.field] &&
                    relation.type === RelationType.HasMany
                ) {
                    instance[relation.field] = [];
                }
            });
        }
    }
}
