<div
    class="status status_{{ status.type }}"
    [ngClass]="borderClass()"
    [title]="status?.label"
    *ngIf="status"
>
    <span class="status-content">
        <i
            aria-hidden="true"
            *ngIf="statusIcons"
            class="fal fa-{{ classIcon }}"
        ></i>
        <ng-container *ngIf="showLabel"> {{ status.label }}</ng-container>
    </span>
</div>
