import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/services/auth/auth.service';

@Component({
    selector: 'dd-redirect',
    template: '',
})
export class RedirectComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
    ) {}

    public ngOnInit(): void {
        const parameters = this.route.snapshot.queryParamMap;
        if (parameters.get('access_token')) {
            const accessToken = parameters.get('access_token');
            const accessTokenExp = parameters.get('expire');
            const expiryTimestamp = parseInt(
                parameters.get('expiry_timestamp'),
            );

            const roleable_name = parameters.get('roleable_name');
            const roleable_id = parameters.get('roleable_id');

            const global_view = parameters.get('global_view') == 'true';

            this.authService.saveAccessData({
                accessToken,
                accessTokenExp,
                expiryTimestamp,
                global_view,
                roleable_name,
                roleable_id,
            });
        }

        if (parameters.get('redirect_to')) {
            this.router.navigateByUrl(parameters.get('redirect_to'));
        }
    }
}
