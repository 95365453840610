import { HasMany } from '@core/relations/has-many';
import { LeasingSettingHelper } from '@leasing-settings/helpers/leasing-setting-helper';
import { LeasingSettingType } from '@leasing-settings/types/leasing-setting.type';
import { InsuranceRate } from './insurance-rate';
import { LeasingCondition } from './leasing-condition';
import { Model } from './model';
import { ServiceRate } from './service-rate';

type LeasingSettingProperty =
    | 'leasingConditions'
    | 'insuranceRates'
    | 'serviceRates';

@HasMany(LeasingCondition, 'leasingConditions')
@HasMany(InsuranceRate, 'insuranceRates')
@HasMany(ServiceRate, 'serviceRates')
export class HasLeasingSettings extends Model {
    public leasingConditions: LeasingCondition[] = [];
    public insuranceRates: InsuranceRate[] = [];
    public serviceRates: ServiceRate[] = [];

    public addLeasingCondition(leasingCondition: LeasingCondition): void {
        if (
            this.leasingConditions.some(
                (t) =>
                    t.productCategory.id ===
                    leasingCondition.productCategory.id,
            )
        ) {
            LeasingSettingHelper.deactivateToday(leasingCondition);
        }
        this.leasingConditions = [...this.leasingConditions, leasingCondition];
    }

    public addInsuranceRate(rate: InsuranceRate): void {
        if (
            this.insuranceRates.some(
                (t) => t.productCategory.id === rate.productCategory.id,
            )
        ) {
            LeasingSettingHelper.deactivateToday(rate);
        }
        this.insuranceRates = [...this.insuranceRates, rate];
    }

    public addServiceRate(rate: ServiceRate): void {
        this.serviceRates = [...this.serviceRates, rate];
    }

    public editInsuranceRate(rate: InsuranceRate): void {
        const foundRate = this.insuranceRates.find(
            (item) => item.id === rate.id,
        );
        Object.assign(foundRate, rate);
        this.insuranceRates = [...this.insuranceRates];
    }

    public editServiceRate(rate: ServiceRate): void {
        const foundRate = this.serviceRates.find((item) => item.id === rate.id);
        Object.assign(foundRate, rate);
        this.serviceRates = [...this.serviceRates];
    }

    public editLeasingCondition(leasingCondition: LeasingCondition): void {
        const foundLeasingCondition = this.leasingConditions.find(
            (item) => item.id === leasingCondition.id,
        );
        Object.assign(foundLeasingCondition, leasingCondition);
        this.leasingConditions = [...this.leasingConditions];
    }

    public deleteLeasingCondition(leasingCondition: LeasingCondition): void {
        this.leasingConditions = [
            ...this.leasingConditions.filter(
                (l) => l.id !== leasingCondition.id,
            ),
        ];
    }

    public deleteInsuranceRate(rate: InsuranceRate): void {
        this.insuranceRates.splice(this.insuranceRates.indexOf(rate), 1);
        this.insuranceRates = [...this.insuranceRates];
    }

    public deleteServiceRate(rate: InsuranceRate): void {
        this.serviceRates.splice(this.serviceRates.indexOf(rate), 1);
        this.serviceRates = [...this.serviceRates];
    }

    public activateLeasingCondition(leasingCondition: LeasingCondition): void {
        this.activateSetting(leasingCondition, 'leasingConditions');
    }

    public activateInsuranceRate(insuranceRate: InsuranceRate): void {
        this.activateSetting(insuranceRate, 'insuranceRates');
    }

    public activateServiceRate(serviceRate: ServiceRate): void {
        this.activateSetting(serviceRate, 'serviceRates');
    }

    private activateSetting(
        setting: LeasingSettingType,
        property: LeasingSettingProperty,
    ): void {
        this.deactivateActiveSetting(setting, property);
        this.deactivateFutureSetting(setting, property);
        this.findAndActivateSetting(setting, property);
        this[property] = [].concat(this[property]);
    }

    private deactivateActiveSetting(
        setting: LeasingSettingType,
        property: LeasingSettingProperty,
    ): void {
        const activeSetting = this[property].find(
            (item: LeasingSettingType) => {
                const baseCondition =
                    LeasingSettingHelper.isActive(item) &&
                    item.productCategory.id === setting.productCategory.id;
                if (item['type'] && setting['type']) {
                    return baseCondition && item['type'] === setting['type'];
                }
                return baseCondition;
            },
        );
        if (activeSetting && activeSetting?.id !== setting.id) {
            LeasingSettingHelper.deactivateTomorrow(activeSetting);
        }
    }

    private deactivateFutureSetting(
        setting: LeasingSettingType,
        property: LeasingSettingProperty,
    ): void {
        const futureSetting = this[property].find(
            (item: LeasingSettingType) => {
                const baseCondition =
                    LeasingSettingHelper.isFuture(item) &&
                    item.productCategory.id === setting.productCategory.id;
                if (item['type'] && setting['type']) {
                    return baseCondition && item['type'] === setting['type'];
                }
                return baseCondition;
            },
        );
        if (futureSetting) {
            LeasingSettingHelper.deactivateToday(futureSetting);
        }
    }

    private findAndActivateSetting(
        setting: LeasingSettingType,
        property: LeasingSettingProperty,
    ): void {
        setting = this[property].find((item) => item.id === setting.id);
        if (setting && LeasingSettingHelper.isInactive(setting)) {
            LeasingSettingHelper.activateTomorrow(setting);
        }
    }
}
