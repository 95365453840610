import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuBlock } from './interfaces/menu-block.interface';

export interface MenuElement {
    menu: MenuBlock[];
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public menuSubject = new BehaviorSubject<MenuElement>({ menu: [] });

    public updateMenu(menu: MenuBlock[], permissions: string[] = []): void {
        this.menuSubject.next(this.getPermissionsMenu(menu, permissions));
    }

    private getPermissionsMenu(
        menu: MenuBlock[],
        permissions: string[]
    ): MenuElement {
        const menuEle: MenuElement = { menu: [] };
        menuEle.menu = menu.map((block) => {
            block.links = block.links.filter((link) => {
                if (link.permissions?.length > 0) {
                    return link.permissions.reduce((hasPermission, role) => {
                        return permissions.includes(role);
                    }, true);
                }
                return true;
            });
            return block;
        });
        return menuEle;
    }
}
