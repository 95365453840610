import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rejectedUser',
})
export class RejectedUserPipe implements PipeTransform {
    public transform(value: string, args?: any): string {
        if (
            args.additional_info === 'Admin Rejected' &&
            args.status.label !== 'Aktiv'
        ) {
            return `${value} <br> <small class="text-danger">Admin abgelehnt</small>`;
        }
        return value;
    }
}
