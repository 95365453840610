<div class="audit-detail">
    <div class="card">
        <div class="card__header">
            <h2
                class="card__header__title"
                translate
            >
                GENERIC.LABELS.COURSE
            </h2>
        </div>
        <div class="card__content">
            <ul class="list-unstyled">
                <li
                    class="audit-detail__item"
                    *ngFor="let audit of audits"
                >
                    <div class="audit-detail__item__date">
                        {{ audit.createdAt | date: 'short' }}
                    </div>
                    <div class="audit-detail__item__content">
                        <span
                            class="audit-detail__item__content__description"
                            >{{ audit.description }}</span
                        >
                        <span
                            class="audit-detail__item__content__user"
                            *ngIf="audit.user"
                            >{{ 'GENERIC.LABELS.FROM_LOWER' | translate }}
                            {{ audit.user }}</span
                        >
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
