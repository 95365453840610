import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../login.service';
import { LoginVerification } from '../login-verification';
import { RedirectionResponse } from '../redirection-response';

@Component({
    selector: 'dd-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit {
    public consentVerification: LoginVerification;
    public submitProcess = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService
    ) {}

    public ngOnInit(): void {
        this.consentVerification =
            this.activatedRoute.snapshot.data.verification;
    }

    public reject(): void {
        this.loginService
            .rejectConsent(this.consentVerification.challenge)
            .subscribe((redirection: RedirectionResponse) => {
                window.location.replace(redirection.redirectTo);
            });
    }

    public accept(): void {
        this.loginService
            .acceptConsent(this.consentVerification.challenge)
            .subscribe((redirection: RedirectionResponse) => {
                window.location.replace(redirection.redirectTo);
            });
    }
}
