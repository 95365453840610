import {
    ChangeDetectionStrategy,
    Component,
    NgZone,
    OnInit,
} from '@angular/core';

interface Flake {
    speed: number;
    velY: number;
    velX: number;
    x: number;
    y: number;
    size: number;
    stepSize: number;
    step: number;
    opacity: number;
}

@Component({
    selector: 'app-snow',
    templateUrl: './snow.component.html',
    styleUrls: ['./snow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnowComponent implements OnInit {
    constructor(private ngZone: NgZone) {}

    public ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            const canvas = document.getElementById(
                'canvas',
            ) as HTMLCanvasElement;
            const ctx = canvas.getContext('2d');
            const flakeCount = 200;
            let flakes: Flake[] = [];
            let mX = -100;
            let mY = -100;

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            function reset(flake: Flake): void {
                flake.x = Math.floor(Math.random() * canvas.width);
                flake.y = 0;
                flake.size = Math.random() * 3 + 2;
                flake.speed = Math.random() * 1 + 0.5;
                flake.velY = flake.speed;
                flake.velX = 0;
                flake.opacity = Math.random() * 0.5 + 0.3;
            }

            function snow(): void {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                flakes.forEach((flake) => {
                    const x = mX;
                    const y = mY;
                    const minDist = 150;
                    const x2 = flake.x;
                    const y2 = flake.y;

                    const dist = Math.sqrt(
                        (x2 - x) * (x2 - x) + (y2 - y) * (y2 - y),
                    );

                    if (dist < minDist) {
                        const force = minDist / (dist * dist);
                        const xcomp = (x - x2) / dist;
                        const ycomp = (y - y2) / dist;
                        const deltaV = force / 2;

                        flake.velX -= deltaV * xcomp;
                        flake.velY -= deltaV * ycomp;
                    } else {
                        flake.velX *= 0.98;
                        if (flake.velY <= flake.speed) {
                            flake.velY = flake.speed;
                        }
                        flake.velX +=
                            Math.cos((flake.step += 0.05)) * flake.stepSize;
                    }

                    ctx.fillStyle = 'rgba(255,255,255,' + flake.opacity + ')';
                    flake.y += flake.velY;
                    flake.x += flake.velX;

                    if (flake.y >= canvas.height || flake.y <= 0) {
                        reset(flake);
                    }

                    if (flake.x >= canvas.width || flake.x <= 0) {
                        reset(flake);
                    }

                    ctx.beginPath();
                    ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
                    ctx.fill();
                });
                requestAnimationFrame(snow);
            }

            function init(): void {
                flakes = Array.from({ length: flakeCount }).map(() => {
                    const speed = Math.random() * 1 + 0.5;

                    return {
                        speed: speed,
                        velY: speed,
                        velX: 0,
                        x: Math.floor(Math.random() * canvas.width),
                        y: Math.floor(Math.random() * canvas.height),
                        size: Math.random() * 3 + 2,
                        stepSize: Math.random() / 30,
                        step: 0,
                        opacity: Math.random() * 0.5 + 0.3,
                    };
                });
            }

            canvas.addEventListener('mousemove', (e) => {
                mX = e.clientX;
                mY = e.clientY;
            });

            window.addEventListener('resize', () => {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
            });

            init();
            snow();
        });
    }
}
