import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ContractFilterRequest } from '@app/contracts/contracts.service';
import { OfferFilterRequest, OffersService } from '@app/offers/offers.service';
import { OrderFilterRequest, OrdersService } from '@app/orders/orders.service';
import { ContractsService } from '@contracts/contracts.service';
import { Status } from '@models/status';
import { Contract } from '@shared/models/contract';
import { Offer } from '@shared/models/offer';
import { Order } from '@shared/models/order';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';

@Component({
    selector: 'mrc-status-steps',
    templateUrl: './status-steps.component.html',
    styleUrls: ['./status-steps.component.scss'],
})
export class StatusStepsComponent implements OnChanges, OnDestroy {
    @Input() public inputOffer: Offer;
    @Input() public inputOrder: Order;
    @Input() public inputContract: Contract;

    @Input() public statusIcons = true;
    @Input() public btnBorder = false;
    @Input() public showLabel = true;

    public offerStatus: Status = {
        id: 0,
        label: 'N.N.',
        tooltip_content: '',
    };

    public orderStatus: Status = {
        id: 0,
        label: 'N.N.',
        tooltip_content: '',
    };

    public contractStatus: Status = {
        id: 0,
        label: 'N.N.',
        tooltip_content: '',
    };

    private _destroyed$ = new Subject<void>();

    constructor(
        private offersService: OffersService,
        private ordersService: OrdersService,
        private contractsService: ContractsService,
    ) {}

    public ngOnChanges(): void {
        if (this.inputOffer) {
            this.offerStatus = this.inputOffer.status;

            this.getDataFromOffer();
        }

        if (this.inputOrder) {
            this.orderStatus = this.inputOrder.status;

            this.getDataFromOrder();
        }

        if (this.inputContract) {
            this.contractStatus = this.inputContract.status;

            this.getDataFromContract();
        }
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private getDataFromOffer(): void {
        const filter_request_order: OrderFilterRequest = {
            offer_id: this.inputOffer.id,
        };
        this.ordersService
            .getFilteredOrders(filter_request_order)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedOrders) => {
                if (paginatedOrders.totalElements > 0) {
                    this.orderStatus = paginatedOrders.data[0].status;
                }
            });

        const filter_request_contract: ContractFilterRequest = {
            offer_id: this.inputOffer.id,
        };

        this.contractsService
            .getFilteredContracts(filter_request_contract)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedContracts) => {
                if (paginatedContracts.totalElements > 0) {
                    this.contractStatus = paginatedContracts.data[0].status;
                }
            });
    }

    private getDataFromOrder(): void {
        const filter_request_offer: OfferFilterRequest = {
            order_id: this.inputOrder.id,
        };

        this.offersService
            .getFilteredOffers(filter_request_offer)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedOffers) => {
                if (paginatedOffers.totalElements > 0) {
                    this.offerStatus = paginatedOffers.data[0].status;
                }
            });

        const filter_request_contract: ContractFilterRequest = {
            order_id: this.inputOrder.id,
        };

        this.contractsService
            .getFilteredContracts(filter_request_contract)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedContracts) => {
                if (paginatedContracts.totalElements > 0) {
                    this.contractStatus = paginatedContracts.data[0].status;
                }
            });
    }

    private getDataFromContract(): void {
        const filter_request_offer: OfferFilterRequest = {
            contract_id: this.inputContract.id,
        };

        this.offersService
            .getFilteredOffers(filter_request_offer)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedOffers) => {
                if (paginatedOffers.totalElements > 0) {
                    this.offerStatus = paginatedOffers.data[0].status;
                }
            });

        const filter_request_order: OrderFilterRequest = {
            contract_id: this.inputContract.id,
        };

        this.ordersService
            .getFilteredOrders(filter_request_order)
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => throwError(() => error)),
            )
            .subscribe((paginatedOrders) => {
                if (paginatedOrders.totalElements > 0) {
                    this.orderStatus = paginatedOrders.data[0].status;
                }
            });
    }
}
