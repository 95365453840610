import { Mixin } from '@common/mixin';
import { startOfToday } from 'date-fns';
import { HasProductCategory } from './has-product-category';
import { Model } from './model';
import { ProductCategory } from './product-category';

export interface LeasingConditionMixin extends Model, HasProductCategory {}
export class LeasingCondition extends Mixin<LeasingConditionMixin>(
    Model,
    HasProductCategory
) {
    public factor: number;
    public id: number;
    public key: number | string;
    public name: string;
    public period = 6;
    public productCategory: ProductCategory;
    public residualValueCustomer = 0;
    public residualValueImputed = 0;
    public activeAt = startOfToday();
    public inactiveAt = null;
}
