import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TitleResolver {
    public currentRouteData?: Data;

    constructor(private translateService: TranslateService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<string> {
        this.currentRouteData = route.data;

        if (!route.data.title) {
            return this.translateService.get('ROUTE_TITLES.SUFFIX');
        }

        return combineLatest({
            suffix: this.translateService.get('ROUTE_TITLES.SUFFIX'),
            title: this.translateService.get(route.data.title),
        }).pipe(map(({ suffix, title }) => `${title} - ${suffix}`));
    }
}
