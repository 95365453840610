<ng-template #template>
    <div class="modal-header justify-content-center modal-confirm">
        <h2 class="modal-title text-center">{{ title }}</h2>
    </div>
    <div
        class="modal-body d-flex flex-column justify-content-center align-items-center text-center modal-confirm"
    >
        <i
            aria-hidden="true"
            *ngIf="icon"
            class="fal fa-{{ icon }}"
        ></i>
        <ng-content></ng-content>
    </div>
    <div
        class="modal-footer d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center modal-confirm"
    >
        <button
            type="button"
            (click)="reject()"
            class="btn btn-light mb-3 mb-sm-0 me-sm-3 mt-0"
        >
            <i
                aria-hidden="true"
                *ngIf="icon"
                class="fal fa-times"
            ></i>
            <span>{{ cancelLabel || 'GENERIC.LABELS.NO' | translate }}</span>
        </button>
        <button
            type="button"
            (click)="accept()"
            class="btn btn-primary"
        >
            <i
                aria-hidden="true"
                *ngIf="icon"
                class="fal fa-check"
            ></i>
            <span>{{ acceptLabel || 'GENERIC.LABELS.YES' | translate }}</span>
        </button>
    </div>
</ng-template>
