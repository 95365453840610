<div *ngIf="this.companyBeamte">
    <p
        class="label mb-0"
        translate
    >
        USER.BEAMTENSTATUS<span
            *ngIf="beamtenControl.hasValidator(Validators.required)"
            >*</span
        >
    </p>
    <mrc-form-item-field [control]="beamtenControl">
        <mat-radio-group
            [formControl]="beamtenControl"
            name="calculator"
            [id]="'beamten-radio-group'"
        >
            <mat-radio-button
                [id]="'beamten-0'"
                [value]="false"
                class="me-5"
                >{{ 'USER.BEAMTENSTATUS_FALSE' | translate }}
            </mat-radio-button>
            <mat-radio-button
                [id]="'beamten-1'"
                [value]="true"
                class="me-5"
                >{{ 'USER.BEAMTENSTATUS_TRUE' | translate }}
            </mat-radio-button>
        </mat-radio-group>
    </mrc-form-item-field>
</div>
