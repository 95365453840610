import { Injectable } from '@angular/core';
import { Factory } from '@shared/factory';
import { Portal, PortalStatus } from '@shared/models/portal';

@Injectable({
    providedIn: 'root',
})
export class PortalFactory extends Factory<Portal> {
    constructor() {
        super(Portal);
    }

    public create(): Portal {
        const portal = super.create();
        portal.status = { id: PortalStatus.Active, label: '' };
        portal.allowEmployeeOfferCreation = true;
        return portal;
    }
}
