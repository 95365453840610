import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatString' })
export class FormatStringPipe implements PipeTransform {
    public transform(value: string, args?: { [key: string]: string }): string {
        for (const key in args) {
            const searchText = `{${key}}`;
            value = value.replace(new RegExp(searchText, 'gi'), args[key]);
        }
        return value;
    }
}
