import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { DOMAIN_MATCH1 } from '@core/constants/regex';

/**
 * @deprecated Use Validator in FormControl
 */
@Directive({
    selector: '[domain][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DomainDirective,
            multi: true,
        },
    ],
})
export class DomainDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const domain = control.value;
        if (domain && !domain.match(DOMAIN_MATCH1)) {
            return {
                domain: 'Ungültige Domäne',
            };
        }
        return null;
    }
}
