import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreakpointService {
    public isMobile$ = this.breakpointObserver
        // Bootstrap md-Breakpoint
        .observe(['(max-width: 767.98px)'])
        .pipe(map((result) => result.matches));

    constructor(private breakpointObserver: BreakpointObserver) {}
}
