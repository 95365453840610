import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../portal-app/material.module';
import { AlertModalComponent } from './alert/alert-modal.component';
import { ConfirmModalMaterialComponent } from './confirm/confirm-modal-material.component';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';

@NgModule({
    imports: [CommonModule, MaterialModule, TranslateModule],
    declarations: [
        ConfirmModalComponent,
        ConfirmModalMaterialComponent,
        AlertModalComponent,
    ],
    exports: [
        ConfirmModalComponent,
        ConfirmModalMaterialComponent,
        AlertModalComponent,
        MaterialModule,
    ],
})
export class ModalsModule {}
