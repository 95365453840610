import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
} from '@angular/forms';

/**
 * @deprecated Use Validators.min in FormControl
 */
@Directive({
    selector: '[min][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MinDirective,
            multi: true,
        },
    ],
})
export class MinDirective {
    @Input() public min: number;

    public validate(control: AbstractControl): ValidationErrors | null {
        if (+control.value < +this.min) {
            return {
                max: `Der minimale Wert ist ${this.min}`,
            };
        }

        return null;
    }
}
