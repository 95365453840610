import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Factory } from '@shared/factory';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UnauthorizedError } from './exceptions/unauthorized.error';
import { LoginVerification } from './external/login-verification';
import { RedirectionResponse } from './external/redirection-response';

@Injectable()
export class LoginService {
    constructor(public httpClient: HttpClient) {}

    public externalLogin(loginData: {
        email: string;
        password: string;
        challenge: string;
    }): Observable<RedirectionResponse> {
        return this.httpClient.post('external/login', loginData).pipe(
            map((response) =>
                new Factory(RedirectionResponse).fromObject(response),
            ),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    return throwError(() => new UnauthorizedError());
                }
                return throwError(() => error);
            }),
        );
    }

    public verifyLoginChallenge(
        challenge: string,
    ): Observable<LoginVerification> {
        return this.httpClient
            .get(`external/login/verify-challenge?challenge=${challenge}`)
            .pipe(
                map((response) =>
                    new Factory(LoginVerification).fromObject(response),
                ),
            );
    }

    public verifyConsentChallenge(
        challenge: string,
    ): Observable<LoginVerification> {
        return this.httpClient
            .get(`external/consent/verify-challenge?challenge=${challenge}`)
            .pipe(
                map((response) =>
                    new Factory(LoginVerification).fromObject(response),
                ),
            );
    }

    public rejectConsent(challenge: string): Observable<RedirectionResponse> {
        return this.httpClient
            .put(`external/consent/${challenge}/reject`, {})
            .pipe(
                map((response) =>
                    new Factory(RedirectionResponse).fromObject(response),
                ),
            );
    }

    public acceptConsent(challenge: string): Observable<RedirectionResponse> {
        return this.httpClient
            .put(`external/consent/${challenge}/accept`, {})
            .pipe(
                map((response) =>
                    new Factory(RedirectionResponse).fromObject(response),
                ),
            );
    }
}
