import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdditionalField } from '@app/additional-fields/interfaces/additional-field';

@Component({
    selector: 'dd-additional-field',
    templateUrl: './additional-field.component.html',
    styleUrls: ['./additional-field.component.scss'],
})
export class AdditionalFieldComponent {
    @Input() public field: AdditionalField;
    @Input() public control: FormControl<string>;
}
