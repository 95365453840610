import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FileListComponent } from './components/file-list/file-list.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { JoinFileTypePipe } from './pipes/join-file-type.pipe';

@NgModule({
    declarations: [FileUploadComponent, JoinFileTypePipe, FileListComponent],
    imports: [CommonModule, TranslateModule],
    exports: [FileUploadComponent, FileListComponent],
})
export class FileUploadModule {}
