import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldType } from '@app/additional-fields/enums/field-type';
import { AdditionalField } from '@app/additional-fields/interfaces/additional-field';
import { uniqueOptionValidator } from '@app/additional-fields/validators/unique-option.validator';

interface AddFieldModalData {
    field: AdditionalField;
    isSubCompany: boolean;
}

@Component({
    selector: 'dd-add-field-modal',
    templateUrl: './add-field-modal.component.html',
    styleUrls: ['./add-field-modal.component.scss'],
})
export class AddFieldModalComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public addFieldForm: FormGroup;
    public options: FormArray<FormControl<string>>;

    public typeItems: { id: string; label: string }[] = [
        { id: FieldType.TEXT, label: 'GENERIC.LABELS.TEXT' },
        { id: FieldType.NUMBER, label: 'GENERIC.LABELS.NUMBER' },
        { id: FieldType.SELECT, label: 'GENERIC.LABELS.SELECT' },
    ];

    constructor(
        private dialogRef: MatDialogRef<AddFieldModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AddFieldModalData,
    ) {}

    public ngOnInit(): void {
        this.options = new FormArray([]);
        this.addFieldForm = new FormGroup({
            label: new FormControl(this.data.field?.label || '', [
                Validators.required,
            ]),
            type: new FormControl(this.data.field?.type || FieldType.TEXT, [
                Validators.required,
            ]),
            minLength: new FormControl<number>(null),
            maxLength: new FormControl<number>(null),
            key: new FormControl(this.data.field?.key || '', [
                Validators.required,
            ]),
            external_key: new FormControl(this.data.field?.external_key || ''),
            placeholder: new FormControl(this.data.field?.placeholder || ''),
            options: this.options,
            isUnique: new FormControl(false),
            enable_api: new FormControl(false),
            enable_uev: new FormControl(false),
            enable_email: new FormControl(false),
            disabled: new FormControl(false),
            isGlobalField: new FormControl({
                value: false,
                disabled: this.data.isSubCompany,
            }),
            required: new FormControl(true),
        });

        this.addFieldForm
            .get('type')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((type: FieldType) => {
                if (type === FieldType.SELECT) {
                    this.addOption();
                    this.options.setValidators([Validators.required]);
                    this.addFieldForm.get('isUnique').disable();
                    this.addFieldForm.get('minLength').reset();
                    this.addFieldForm.get('maxLength').reset();
                } else {
                    this.options.clear();
                    this.options.setValidators([]);
                    this.addFieldForm.get('isUnique').enable();
                }

                this.options.updateValueAndValidity();
            });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public add(): void {
        this.dialogRef.close(this.addFieldForm.getRawValue());
    }

    public showOptionsControl(): boolean {
        return this.addFieldForm.get('type').value === FieldType.SELECT;
    }

    public showMinMax(): boolean {
        const type = this.addFieldForm.get('type').value;
        return type === FieldType.TEXT || type === FieldType.NUMBER;
    }

    public addOption(): void {
        this.options.push(
            new FormControl('', [
                Validators.required,
                uniqueOptionValidator(this.options),
            ]),
        );
    }

    public removeOption(idx: number): void {
        this.options.removeAt(idx);
    }
}
