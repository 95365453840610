import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LogoService } from '@core/services/logo/logo.service';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { UnauthorizedError } from '../../exceptions/unauthorized.error';
import { LoginService } from '../../login.service';
import { LoginVerification } from '../login-verification';
import { RedirectionResponse } from '../redirection-response';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mrc-login',
    templateUrl: './external-login.component.html',
    styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
    public loginForm = this.formBuilder.group({
        email: this.formBuilder.control(null, [
            Validators.required,
            CustomEmailValidator,
        ]),
        password: this.formBuilder.control(null, [Validators.required]),
        challenge: this.formBuilder.control(null),
    });
    public errorMessage: string;
    public submitProcess = false;
    public loginVerification: LoginVerification;

    constructor(
        private loginService: LoginService,
        public logoService: LogoService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private toasterService: ToastrService,
        private translateService: TranslateService,
    ) {}

    public ngOnInit(): void {
        this.loginVerification = this.activatedRoute.snapshot.data.verification;
        this.loginForm
            .get('challenge')
            .setValue(this.loginVerification.challenge);
    }

    public login(): void {
        this.loginService
            .externalLogin(this.loginForm.value)
            .pipe(
                catchError((error) => {
                    if (error instanceof UnauthorizedError) {
                        this.toasterService.error(
                            this.translateService.instant(error.message),
                        );
                        Object.values(this.loginForm.controls).forEach(
                            (control) =>
                                control.setErrors({
                                    invalid: 'Ungültig oder nicht vorhanden',
                                }),
                        );
                    }
                    return throwError(() => error);
                }),
            )
            .subscribe((login: RedirectionResponse) => {
                window.location.replace(login.redirectTo);
            });
    }
}
