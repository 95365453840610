export const COMPANY_SLUG = /^[a-z0-9]+$/;
export const COST_CENTER = /[a-zA-Z\d_-]*/;
export const DOMAIN_MATCH1 = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/;
export const DOMAIN_MATCH2 = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const DOT_REPLACE = /\./g;
export const EMAIL = /^\S+@\S+\.\D{2,}$/;
export const EMAIL_MATCH =
    /^[a-zA-Z\d!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z\d.-]+[.][a-zA-Z\d.]{2,14}$/i;
export const FILENAME_MATCH = /filename=(.+)/;
export const FILENAME_REPLACE1 = /"/g;
export const FILENAME_REPLACE2 = /'/g;
export const HEX_MATCH1 = /^#([A-Fa-f0-9]{3}){1,2}$/;
export const HEX_MATCH2 = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
export const IBAN =
    /^([A-Z]{2}[ '+'\\\\'+'-]?[0-9]{2})(?=(?:[ '+'\\\\'+'-]?[A-Z0-9]){9,30})((?:[ '+'\\\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\\\'+'-]?[A-Z0-9]{1,3})?/;
export const INITIALS_MATCH = /\b(\w)/g;
export const NON_NUMBER_REPLACE = /\D+/g;
export const NUMBER = /^\d*$/;
export const CURRENCY = /^\d*,?\d{2}$/;
export const NUMBER_MATCH = /^(\d{1,6})(,\d{1,2})?$/;
export const PASSWORD = [/^.{10,15}$/, /\d/, /[a-z]/, /[A-Z]/, /[^a-zA-Z\d]/];
export const PASSWORD_MATCH =
    /^(?=.*\d)(?=.*[^a-zA-Z\d\s:])(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,15}$/;
export const POSTAL_CODE = /^\d{5}$/;
export const PRV_NUMBER = /^[A-Z]{3}-\d{5,6}$/;
export const PHONE_PREFIX = /\d{2}/;
export const PHONE1 = /^0\d+$/;
export const PHONE2 = /^0\d+\/*\d+$/;
export const PHONE3 = /^0\d{5,14}$/;
export const PHONE4 = /^[+]*[(]?\d{1,4}\)?[-\s./0-9](\d[-+/\s]*){7,}/;
export const REQUIRED = /^\S+.*$/gm;
/**
 * Serial numbers of bikes can't begin with this forbidden pattern.
 * The forbidden pattern is used for automatically generated serial numbers.
 */
export const SERIAL_NUMBER = /^(?!.*FR-\d{7}).*$/;
export const WORKPLACE_DISTANCE = /\d{1,5}(?:\.\d{0,2})?$/;
