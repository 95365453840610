import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'mrc-page-404',
    templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
    public errorCode: string;
    public errorMessage: string;

    constructor(public activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.errorCode = this.activatedRoute.snapshot.paramMap.get('errorCode');
        this.errorMessage =
            this.activatedRoute.snapshot.queryParamMap.get('message');
    }
}
