import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { REQUIRED } from '@core/constants/regex';

/**
 * @deprecated Use Validator.required in FormControl
 */
@Directive({
    selector:
        '[required][formControlName],[required][formControl],[required][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: RequiredDirective,
            multi: true,
        },
    ],
})
export class RequiredDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const fieldValue = control.value;

        if (!new RegExp(REQUIRED).test(String(fieldValue))) {
            // multiline regex
            return {
                required: 'Dieses Feld ist erforderlich',
            };
        }

        return null;
    }
}
