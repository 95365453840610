import { Component, computed, input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'dd-image',
    templateUrl: './image.component.html',
    standalone: true,
    imports: [],
})
export class ImageComponent {
    public alt = input('');
    public src = input.required<string>();

    public path = computed(() => {
        let _path: string;
        if (this.src()) {
            if (this.src().includes(';base64')) {
                _path = this.src();
            } else if (this.src().includes(`${environment.apiUrl}/storage/`)) {
                _path = `${this.src()}`;
            } else if (this.src().includes('/storage/')) {
                _path = `${environment.apiUrl}${this.src()}`;
            } else {
                _path = `${environment.apiUrl}/storage/${this.src()}`;
            }
        }
        return _path;
    });
}
