export class Page<T> {
    public pageSize = 20;
    public size = 20;
    public data: T[] = [];
    public page = 1;
    public total = 0;
    public totalElements = 0;
    public totalPages = 0;
    public pageNumber = 0;

    public getPageSize(): number {
        if (this.pageSize < 20 || this.size < 20) {
            return 20;
        } else {
            return this.size;
        }
    }
}
