import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
} from '@angular/forms';

export class FormHelper {
    /**
     * Takes a {@link FormControl}, (nested) {@link FormGroup} or {@link FormArray} and marks every control dirty.
     * @param abstractControl {@link AbstractControl} {@link FormControl}, (nested) {@link FormGroup} or {@link FormArray} to validate
     */
    public static markAllAsDirty(abstractControl: AbstractControl): void {
        if (abstractControl instanceof FormControl) {
            this.markFormControlAsDirty(abstractControl);
        } else if (abstractControl instanceof FormGroup) {
            this.markFormGroupAsDirty(abstractControl);
        } else if (abstractControl instanceof FormArray) {
            this.markFormArrayAsDirty(abstractControl);
        }
    }

    private static markFormGroupAsDirty(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.get(key);

            if (control instanceof FormControl) {
                this.markFormControlAsDirty(control);
            } else if (control instanceof FormGroup) {
                this.markFormGroupAsDirty(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsDirty(control);
            }
        });
    }

    private static markFormArrayAsDirty(formArray: FormArray): void {
        formArray.controls.forEach((control) => {
            if (control instanceof FormControl) {
                this.markFormControlAsDirty(control);
            } else if (control instanceof FormGroup) {
                this.markFormGroupAsDirty(control);
            }
        });
    }

    private static markFormControlAsDirty(control: FormControl): void {
        control.markAsDirty();
    }
}
