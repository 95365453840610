import { Injectable } from '@angular/core';
import { Factory } from '../shared/factory';
import { Company, CompanyStatus } from '../shared/models/company';

@Injectable({
    providedIn: 'root',
})
export class CompaniesFactory extends Factory<Company> {
    constructor() {
        super(Company);
    }

    public create(): Company {
        const company = super.create();
        company.status = { id: CompanyStatus.Active, label: '' };
        return company;
    }
}
