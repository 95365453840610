import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminSettings } from '@shared/models/admin-settings';
import { map, Observable } from 'rxjs';

@Injectable()
export class MaintenanceService {
    private SkipModuleUrl = 'X-Skip-Module-Url';

    constructor(private httpClient: HttpClient) {}

    public getSettings(): Observable<AdminSettings> {
        const headers = new HttpHeaders().set(this.SkipModuleUrl, '');
        return this.httpClient
            .get<{ payload: AdminSettings }>(`system-api/settings`, {
                headers,
            })
            .pipe(map((result) => result.payload));
    }
}
