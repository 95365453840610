import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@core/helpers/form-helper';

/**
 * Directive to focus the first invalid input field after submitting the form.
 * By submitting the form all form controls will be marked as dirty.
 */
@Directive({
    selector: 'form[ddFocusInvalidInput]',
})
export class FocusInvalidInputDirective {
    @Input() public formGroup: FormGroup;

    constructor(
        private elementRef: ElementRef,
        private translateService: TranslateService,
        private liveAnnouncer: LiveAnnouncer,
    ) {}

    @HostListener('ngSubmit')
    public check(): void {
        FormHelper.markAllAsDirty(this.formGroup);
        let invalidInput =
            this.elementRef.nativeElement.querySelector('.ng-invalid');
        if (invalidInput) {
            // Digg deeper if the form is nested
            while (invalidInput.tagName === 'FORM') {
                invalidInput = invalidInput.querySelector('.ng-invalid');
            }
            // Take a look inside if it is not a native element
            if (
                !['INPUT', 'SELECT', 'TEXTAREA'].includes(invalidInput.tagName)
            ) {
                invalidInput = invalidInput.querySelector('input');
            }

            this.liveAnnouncer.announce(
                this.translateService.instant(
                    'GENERIC.INVALID_FORM_FIELDS_WAS_SELECTED',
                ),
            );
            invalidInput.focus();
        }
    }
}
