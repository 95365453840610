<h2
    *ngIf="data.title"
    mat-dialog-title
    translate
>
    {{ data.title }}
</h2>
<mat-dialog-content>
    <p
        [ngClass]="data.contentClass"
        translate
    >
        {{ data.content }}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        type="button"
        class="btn btn-outline-dark me-2"
        [ngClass]="data.rejectBtnClass || 'btn-outline-dark'"
        (click)="reject()"
        translate
        data-testid="reject-button"
    >
        {{ data.rejectBtnLabel || 'GENERIC.LABELS.NO' | translate }}
    </button>
    <button
        type="button"
        class="btn"
        [ngClass]="data.acceptBtnClass || 'btn-primary'"
        (click)="accept()"
        translate
        data-testid="accept-button"
    >
        {{ data.acceptBtnLabel || 'GENERIC.LABELS.YES' | translate }}
    </button>
</mat-dialog-actions>
