<div class="dialog-close">
    <button
        type="button"
        class="btn-close"
        [attr.aria-label]="'GENERIC.ACTIONS.CLOSE' | translate"
        mat-dialog-close
    ></button>
</div>
<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
    <div [innerHtml]="text"></div>
    <mat-divider></mat-divider>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        type="button"
        class="btn btn-primary"
        mat-dialog-close
        translate
    >
        GENERIC.ACTIONS.CLOSE
    </button>
</mat-dialog-actions>
