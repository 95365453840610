import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { EMAIL } from '@core/constants/regex';
import { PortalService } from '@portals/portal.service';
import {
    Observable,
    catchError,
    filter,
    map,
    of,
    switchMap,
    timer,
} from 'rxjs';

export const CHECK_EMAIL_VALIDATOR_DEBOUNCE_TIME_IN_MS = 300;

export function checkEmailValidator(
    portalService: PortalService,
    ignoreSelf = false,
    companySlug?: string,
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const input = control.value;
        return timer(CHECK_EMAIL_VALIDATOR_DEBOUNCE_TIME_IN_MS).pipe(
            filter(() => {
                if (input === '') return false;
                return RegExp(EMAIL).test(input);
            }),
            switchMap(() =>
                portalService.checkEmail(input, ignoreSelf, companySlug).pipe(
                    map((result) => {
                        if (result?.duplicate) {
                            return { duplicate: true };
                        }
                        if (result?.isNotWhitelisted) {
                            return { isNotWhitelisted: true };
                        }
                        if (result?.isBlacklistedDomain) {
                            return { isBlacklistedDomain: true };
                        }
                        return null;
                    }),
                    catchError(() => of(null)),
                ),
            ),
        );
    };
}
