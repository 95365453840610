import { FormControl, ValidatorFn } from '@angular/forms';

export type CustomPatternValidatorParams = {
    // key used in form control error object and ValidationErrorsComponent to select the right message
    validationKey: string;
    pattern: RegExp | RegExp[];
};
export const customPatternValidator = (
    config: CustomPatternValidatorParams,
): ValidatorFn => {
    return (control: FormControl) => {
        const regExps: RegExp[] = Array.isArray(config.pattern)
            ? config.pattern
            : [config.pattern];
        if (
            control.value &&
            regExps.some((regExp) => !control.value.toString().match(regExp))
        ) {
            return {
                [config.validationKey]: false,
            };
        } else {
            return null;
        }
    };
};
