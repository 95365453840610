import { Component, Input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

export enum FormItemFieldContentType {
    default = 'default',
    matCheckbox = 'mat-checkbox',
}

@Component({
    selector: 'mrc-form-item-field',
    templateUrl: './form-item-field.component.html',
    styleUrls: ['./form-item-field.component.scss'],
})
export class FormItemFieldComponent {
    @Input() public control: AbstractControl;
    @Input() public key: string;
    @Input() public label: string;
    @Input() public id: string;
    @Input() public contentType: FormItemFieldContentType =
        FormItemFieldContentType.default;
    @Input() public layout: string;
    @Input() public reversed = false;
    @Input() public tooltipText: string;
    @Input() public isOutCard: boolean;

    protected readonly FormItemFieldContentType = FormItemFieldContentType;

    public get layoutClasses(): string {
        return this.layout?.replace('inline', 'form-group-inline') ?? '';
    }

    public get contentBasedID(): string {
        switch (this.contentType) {
            case FormItemFieldContentType.matCheckbox:
                return this.id + '-input';
            default:
                return this.id;
        }
    }

    public isRequired(): boolean {
        return (
            this.control?.enabled &&
            (this.control?.hasValidator(Validators.required) ||
                this.control?.hasValidator(Validators.requiredTrue))
        );
    }
}
