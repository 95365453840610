import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { NUMBER_MATCH } from '@core/constants/regex';

/**
 * @deprecated Use Pattern Validator in FormControl
 */
@Directive({
    selector: '[number][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: NumberDirective,
            multi: true,
        },
    ],
})
export class NumberDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const number = control.value;

        if (number && !String(number).match(NUMBER_MATCH)) {
            return {
                number: `Das Feld muss eine Zahl sein`,
            };
        }
        return null;
    }
}
