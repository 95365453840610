import { MediaMatcher } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SystemAdminRoutes } from './app.routes';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    MAT_PAGINATOR_DEFAULT_OPTIONS,
    MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { WebpackTranslateLoader } from '@app/i18n/webpack-translate.loader';
import { CoreModule } from '@core/core.module';
import { ApiInterceptor } from '@core/services/http/api.interceptor';
import { ProfileService } from '@core/services/profile/profile.service';
import { RedirectService } from '@core/services/redirect/redirect.service';
import { SettingsService } from '@core/services/settings/settings.service';
import { MODULE_ACCESS_TOKEN } from '@core/tokens/module-access-token.token';
import { MODULE_URL } from '@core/tokens/module-url.token';
import { MODULE } from '@core/tokens/module.token';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PublicModule } from '@public/public.module';
import { SharedModule } from '@shared/shared.module';
import { MatPaginatorIntlDe } from '@shared/table-utilities/mat-pagination-intl';
import {
    PROTECTED_FALLBACK_PAGE_URI,
    PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule.forRoot(),
        PublicModule,
        BrowserModule,
        BrowserAnimationsModule,
        SystemAdminRoutes,
        SharedModule,
        MatProgressBarModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
        }),
    ],
    providers: [
        { provide: MODULE, useValue: 'system' },
        { provide: MODULE_ACCESS_TOKEN, useValue: 'access_token' },
        { provide: MODULE_URL, useValue: `/system-api` },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        SettingsService,
        ProfileService,
        RedirectService,
        MediaMatcher,
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: `/admin` },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: `/login` },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
        {
            provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
            useValue: { diameter: 32, strokeWidth: 3, color: 'accent' },
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
                showFirstLastButtons: true,
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class SystemAdminAppModule {}
