import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { Factory } from '@shared/factory';
import { Portal } from '@shared/models/portal';
import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    ReplaySubject,
    catchError,
    lastValueFrom,
    map,
    of,
    share,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { PortalFactory } from './portal-factory';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    private currentPortalObservable: Observable<Portal>;

    constructor(
        private httpClient: HttpClient,
        private portalFactory: PortalFactory,
        private toastrService: ToastrService,
        private translateSerivce: TranslateService,
    ) {}

    public get(id): Observable<Portal> {
        return this.httpClient
            .get(`portals/${id}`)
            .pipe(
                map(
                    (response) =>
                        <Portal>this.portalFactory.fromResponse(response),
                ),
            );
    }

    public all(): Observable<Portal[]> {
        return this.httpClient.get<any>('portals/all').pipe(
            catchError((error) => throwError(() => error)),
            map(({ payload }) => new Factory(Portal).fromArray(payload)),
        );
    }

    public getPaginatedPortals(
        page: number,
        pageSize: number,
    ): Observable<Portal[]> {
        const params = new HttpParams()
            .set('page', page)
            .set('pageSize', pageSize);
        return this.httpClient
            .get<
                ResponseGeneric<{ data: Portal[] }>
            >('portals', { params: params })
            .pipe(map((res) => res.payload.data));
    }

    public create(portal: Portal): Observable<Portal> {
        return this.httpClient
            .post('portals', {
                ...portal,
                logo: null,
                leasingablePdf: null,
                policyPdf: null,
                imprintPdf: null,
            })
            .pipe(
                map(
                    (response) =>
                        <Portal>this.portalFactory.fromResponse(response),
                ),
                switchMap(this.handleFileUploads(portal)),
                tap(() =>
                    this.toastrService.success(
                        this.translateSerivce.instant('SUCCESS.PORTAL_CREATED'),
                    ),
                ),
            );
    }

    public update(portal: Portal): Observable<Portal> {
        return this.httpClient
            .put(`portals/${portal.id}`, {
                ...portal,
                logo: typeof portal.logo === 'object' ? null : portal.logo,
                leasingablePdf:
                    typeof portal.leasingablePdf === 'object'
                        ? null
                        : portal.leasingablePdf,
                policyPdf:
                    typeof portal.policyPdf === 'object'
                        ? null
                        : portal.policyPdf,
                imprintPdf:
                    typeof portal.imprintPdf === 'object'
                        ? null
                        : portal.imprintPdf,
            })
            .pipe(
                map(
                    (response) =>
                        <Portal>this.portalFactory.fromResponse(response),
                ),
                switchMap(this.handleFileUploads(portal)),
                tap(() =>
                    this.toastrService.success(
                        this.translateSerivce.instant('SUCCESS.PORTAL_UPDATED'),
                    ),
                ),
            );
    }

    public getCurrentPortal(): Observable<Portal> {
        if (!this.currentPortalObservable) {
            const headers = new HttpHeaders().append(SkipModuleUrl, '');
            this.currentPortalObservable = this.httpClient
                .get(`portal-info`, { headers })
                .pipe(
                    map((response) => {
                        return this.portalFactory.fromObject(response);
                    }),
                    share({
                        connector: () => new ReplaySubject(1),
                        resetOnError: false,
                        resetOnComplete: false,
                        resetOnRefCountZero: true,
                    }),
                );
        }
        return this.currentPortalObservable;
    }

    public getCurrentPortalPromise(): Promise<Portal> {
        if (!this.currentPortalObservable) {
            const headers = new HttpHeaders().append(SkipModuleUrl, '');
            this.currentPortalObservable = this.httpClient
                .get(`portal-info`, { headers })
                .pipe(
                    map((response) => {
                        return this.portalFactory.fromObject(response);
                    }),
                    share({
                        connector: () => new ReplaySubject(1),
                        resetOnError: false,
                        resetOnComplete: false,
                        resetOnRefCountZero: true,
                    }),
                );
        }
        return lastValueFrom(this.currentPortalObservable);
    }

    public getCurrentPortalNonCached(): Observable<Portal> {
        const headers = new HttpHeaders().append(SkipModuleUrl, '');
        return this.httpClient
            .get<Portal>(`portal-info`, { headers })
            .pipe(map((portal) => this.portalFactory.fromObject(portal)));
    }

    private handleFileUploads(
        portal: Portal,
    ): (portalResponse: Portal) => Observable<any> {
        return (portalResponse: Portal): Observable<any> => {
            let observable = of(
                Object.assign(portal, { id: portalResponse.id }),
            );
            if (portal.logo instanceof File) {
                observable = this.pipeToLogoObservable(observable);
            }
            if (portal.leasingablePdf instanceof File) {
                observable = this.pipeToLeasingablePdfObservable(observable);
            }
            if (portal.policyPdf instanceof File) {
                observable = this.pipeToPolicyPdfObservable(observable);
            }
            if (portal.imprintPdf instanceof File) {
                observable = this.pipeToImprintPdfObservable(observable);
            }
            return observable.pipe(
                map((portalAfterUploads) => {
                    return Object.assign(portalResponse, {
                        logo: portalAfterUploads.logo,
                        leasingablePdf: portalAfterUploads.leasingablePdf,
                        policyPdf: portalAfterUploads.policyPdf,
                        imprintPdf: portalAfterUploads.imprintPdf,
                    });
                }),
            );
        };
    }

    private pipeToLogoObservable(
        observable: Observable<Portal>,
    ): Observable<any> {
        return observable.pipe(
            switchMap((portal) =>
                this.uploadLogo(`portals/${portal.id}/files`, portal.logo).pipe(
                    map((logo) => {
                        portal.logo = logo;
                        return portal;
                    }),
                ),
            ),
        );
    }

    private pipeToLeasingablePdfObservable(
        observable: Observable<Portal>,
    ): Observable<any> {
        return observable.pipe(
            switchMap((portal) =>
                this.uploadLeasingablePdf(
                    `portals/${portal.id}/files`,
                    portal.leasingablePdf,
                ).pipe(
                    map((leasingablePdf) => {
                        portal.leasingablePdf = leasingablePdf;
                        return portal;
                    }),
                ),
            ),
        );
    }

    private pipeToPolicyPdfObservable(
        observable: Observable<Portal>,
    ): Observable<any> {
        return observable.pipe(
            switchMap((portal) =>
                this.uploadPolicyPdf(
                    `portals/${portal.id}/files`,
                    portal.policyPdf,
                ).pipe(
                    map((policyPdf) => {
                        portal.policyPdf = policyPdf;
                        return portal;
                    }),
                ),
            ),
        );
    }

    private pipeToImprintPdfObservable(
        observable: Observable<Portal>,
    ): Observable<any> {
        return observable.pipe(
            switchMap((portal) =>
                this.uploadImprintPdf(
                    `portals/${portal.id}/files`,
                    portal.imprintPdf,
                ).pipe(
                    map((imprintPdf) => {
                        portal.imprintPdf = imprintPdf;
                        return portal;
                    }),
                ),
            ),
        );
    }

    private uploadLogo(url: string, file: string | Blob): Observable<any> {
        const formData = new FormData();
        formData.append('logo', file);
        return this.upload(url, formData).pipe(
            map((response) => response.payload.logo),
        );
    }

    private uploadLeasingablePdf(
        url: string,
        file: string | Blob,
    ): Observable<any> {
        const formData = new FormData();
        formData.append('leasingablePdf', file);
        return this.upload(url, formData).pipe(
            map((response) => response.payload.leasingablePdf),
        );
    }

    private uploadPolicyPdf(url: string, file: string | Blob): Observable<any> {
        const formData = new FormData();
        formData.append('policyPdf', file);
        return this.upload(url, formData).pipe(
            map((response) => response.payload.policyPdf),
        );
    }

    private uploadImprintPdf(
        url: string,
        file: string | Blob,
    ): Observable<any> {
        const formData = new FormData();
        formData.append('imprintPdf', file);
        return this.upload(url, formData).pipe(
            map((response) => response.payload.imprintPdf),
        );
    }

    private upload(url: string, formData: FormData): Observable<any> {
        return this.httpClient.post<any>(url, formData).pipe(
            catchError((error) => {
                this.toastrService.error(
                    this.translateSerivce.instant(
                        'ERROR.PORTAL_LOGO_UPLOAD_ERROR',
                    ),
                );
                console.error(error);
                return throwError(() => error);
            }),
        );
    }

    public checkEmail(
        email: string,
        ignoreSelf = false,
        companySlug?: string,
    ): Observable<{
        duplicate: boolean;
        isNotWhitelisted: boolean;
        isBlacklistedDomain: boolean;
    }> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.post<{
            duplicate: boolean;
            isNotWhitelisted: boolean;
            isBlacklistedDomain: boolean;
        }>(
            `portal-api/v1/check-email`,
            { email, ignoreSelf, companySlug },
            { headers: headers },
        );
    }
}
