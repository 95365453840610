<mat-tab-group
    *ngIf="isCompany()"
    class="d-none d-sm-block"
    [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="changedTab($event)"
    animationDuration="0ms"
>
    <mat-tab [label]="'Alle'"></mat-tab>
    <mat-tab
        [label]="status.label"
        *ngFor="let status of statusList"
    >
        <ng-template
            mat-tab-label
            *ngIf="status.has_tooltip"
        >
            {{ status.label }}&nbsp;
            <i
                class="fal fa-info-circle fs-6"
                [attr.aria-label]="status.tooltip_content"
                [matTooltip]="status.tooltip_content"
            ></i>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<mat-tab-group
    *ngIf="!isCompany()"
    class="d-none d-sm-block"
    [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="changedTab($event)"
    animationDuration="0ms"
>
    <mat-tab [label]="'Alle'"></mat-tab>
    <mat-tab
        [label]="status.label"
        *ngFor="let status of statusList"
    >
        <ng-template
            mat-tab-label
            *ngIf="status.has_tooltip"
        >
            {{ status.label }}&nbsp;
            <i
                class="fal fa-info-circle fs-6"
                [attr.aria-label]="status.tooltip_content"
                [matTooltip]="status.tooltip_content"
            ></i>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<div
    class="d-sm-none mb-5 row text-center status_tabs_mobile flex-row flex-nowrap"
>
    <div
        class="col-5 status me-3"
        (click)="changedTabMobile('Alle', 0)"
        [class.active]="selectedIndexTabMobile === 0"
    >
        <span class="status-content">Alle</span>
    </div>
    <div
        class="col-5 status me-3"
        *ngFor="let status of statusList; let i = index"
        [ngClass]="'status_' + status.type"
        [class.active]="selectedIndexTabMobile === i + 1"
    >
        <span
            class="status-content"
            [ngClass]="showIcon ? 'with_mat-icon' : ''"
            (click)="changedTabMobile(status.label, i + 1)"
            >{{ status.label }}</span
        >
        <i
            *ngIf="showIcon"
            class="fal fa-info-circle fs-6"
            [attr.aria-label]="status.tooltip_content"
            [matTooltip]="status.tooltip_content"
        ></i>
    </div>
</div>
