import { CurrencyPipe } from '@angular/common';
import {
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOT_REPLACE } from '@core/constants/regex';

@Directive({
    selector: '[mrcCurrencyMask]',
    exportAs: 'mrcCurrencyMask',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyMaskDirective),
            multi: true,
        },
    ],
})
export class CurrencyMaskDirective implements ControlValueAccessor {
    @Output() public value: EventEmitter<number> = new EventEmitter();

    public storedValue: number;
    public lastParsed = '';
    @Input() public symbol: string;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly currencyPipe: CurrencyPipe,
    ) {}

    private onChangeHandler = (change): void => {
        return;
    };
    private onTouchedHandler = (): void => {
        return;
    };

    @HostListener('focus', ['$event'])
    public handleFocus(): void {
        this.onTouchedHandler();
        if (this.lastParsed) {
            this.elementRef.nativeElement.value = this.lastParsed;
        }
    }

    @HostListener('input', ['$event'])
    public handleInput(): void {
        this.storedValue = this.toNumber(this.elementRef.nativeElement.value);
        this.onChangeHandler(this.storedValue);
        this.value.emit(this.storedValue);
    }

    @HostListener('blur', ['$event'])
    public handleBlur(): void {
        this.lastParsed = this.elementRef.nativeElement.value;
        this.elementRef.nativeElement.value = this.convertCurrency(
            this.storedValue,
        );
    }

    private toNumber(value: string): number {
        const comaSplit = value.split(',');
        let decimal, integer;
        if (comaSplit.length > 1) {
            decimal = comaSplit[comaSplit.length - 1];
            integer = comaSplit
                .slice(0, comaSplit.length - 1)
                .join()
                .replace('.', '');
            return Number(`${integer}.${decimal}`);
        }
        return Number(value.replace(new RegExp(DOT_REPLACE), ''));
    }

    public convertCurrency(value: number): string {
        const currency = this.currencyPipe.transform(value, 'EUR', this.symbol);
        if (currency) {
            return currency.trim();
        }
        return value ? value.toString() : '';
    }

    public registerOnChange(fn: any): void {
        this.onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.elementRef.nativeElement.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.storedValue = obj;
        this.lastParsed = this.convertCurrency(this.storedValue);
        this.elementRef.nativeElement.value = this.lastParsed;
    }
}
