import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
})
export class KeysPipe implements PipeTransform {
    public transform(value: object): string[] {
        if (value) {
            return Object.keys(value);
        }
        return [];
    }
}
