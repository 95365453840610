import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Component,
    DestroyRef,
    OnInit,
    TemplateRef,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdditionalFieldService } from '@app/additional-fields/additional-field.service';
import { FieldType } from '@app/additional-fields/enums/field-type';
import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import { LoginAsResponse } from '@app/users/users.service';
import { CompaniesService } from '@companies/companies.service';
import { NUMBER } from '@core/constants/regex';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { RedirectService } from '@core/services/redirect/redirect.service';
import { TypedFormGroup } from '@core/types/form.type';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import {
    PrivacyPolicyComponent,
    PrivacyPolicyData,
    PrivacyPolicyType,
} from '@layout/privacy-policy/privacy-policy.component';
import { Company } from '@models/company';
import { Portal } from '@models/portal';
import { PortalService } from '@portals/portal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { pairwise, startWith } from 'rxjs';
import { duplicateAttributeValidator } from '@app/additional-fields/validators/duplicate-attribute.validator';
import { checkEmailValidator } from '@core/validators/check-email-validator';

interface AzureRegistrationInfo {
    email: string;
    first_name: string;
    last_name: string;
    employee_number?: string;
    cost_centre?: string;
    company_id?: number;
    calculator: number;
    attributes?: FieldAttribute[];
    token: string;
}

@Component({
    selector: 'dd-external-jwt-registration',
    templateUrl: './external-jwt-registration.component.html',
    styleUrls: ['./external-jwt-registration.component.scss'],
})
export class ExternalJwtRegistrationComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public allow_public_registration: boolean;
    public policy: string;
    public externalRegistrationForm: FormGroup<
        TypedFormGroup<{
            selectedCompany: Company;
            calculator: boolean;
            email: string;
            firstName: string;
            lastName: string;
            token: string;
            externalUserId: string;
            employeeNumber?: string;
            costCenter?: string;
            [key: string]: any;
        }>
    >;

    @ViewChild('policyModal') private policyModal: TemplateRef<any>;
    public actionUrl: string;
    public modalRef: BsModalRef;

    public company: Company;
    public childCompanies: Company[] = [];
    private selectedCompany: FormControl<Company>;
    public calculator: FormControl<boolean>;
    public emailFormControl: FormControl<string>;

    public timeLeft: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public externalUserId: string;
    public type = 'jwt';
    public externalToken: string;
    public isSubmitClicked = false;
    public civilServant = false;

    public slug = this.activatedRoute.snapshot.queryParamMap.get('company');

    constructor(
        public httpClient: HttpClient,
        public activatedRoute: ActivatedRoute,
        public companiesService: CompaniesService,
        public portalService: PortalService,
        private additionalFieldService: AdditionalFieldService,
        private redirectService: RedirectService,
        private dialog: MatDialog,
    ) {}

    public ngOnInit(): void {
        const companySlug =
            this.activatedRoute.snapshot.queryParamMap.get('company');
        const expirationTimestamp = parseInt(
            this.activatedRoute.snapshot.queryParamMap.get('exp'),
        );
        this.firstName =
            this.activatedRoute.snapshot.queryParamMap.get('first_name');
        this.lastName =
            this.activatedRoute.snapshot.queryParamMap.get('last_name');
        this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
        this.externalUserId =
            this.activatedRoute.snapshot.queryParamMap.get('external_user_id');
        this.timeLeft = Math.floor(
            expirationTimestamp - new Date().getTime() / 1000,
        );
        if (this.activatedRoute.snapshot.queryParamMap.has('type')) {
            this.type = this.activatedRoute.snapshot.queryParamMap.get('type');
        }
        if (this.type === 'azure') {
            this.actionUrl = `external/azure/register/azure/${companySlug}`;
        } else if (this.type === 'jwt') {
            this.actionUrl = `external/company/register/jwt/${companySlug}`;
        }
        this.externalToken = this.getCookieValueByName('external_token=');
        this.initForm();

        this.companiesService.loadCompanyDataBySlug(companySlug);

        this.companiesService._companyData$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (company: Company) => {
                    if (company) {
                        this.civilServant = company.show_beamte_calculator;
                        this.externalRegistrationForm.controls.selectedCompany.setValue(
                            company,
                        );
                    }
                },
            });

        this.companiesService._childCompanies$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (childCompanies: Company[]) => {
                    if (childCompanies) {
                        this.childCompanies = childCompanies;
                        if (this.childCompanies.length > 1) {
                            this.externalRegistrationForm.controls.selectedCompany.reset();
                        }
                    }
                },
            });

        this.portalService
            .getCurrentPortal()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((portal: Portal) => {
                this.policy = portal.policy;
            });

        this.startTimer();
    }

    private startTimer(): void {
        setInterval(() => {
            this.timeLeft -= 1;
        }, 1000);
    }

    private initForm(): void {
        this.calculator = new FormControl(true);
        this.selectedCompany = new FormControl(
            null,
            this.childCompanies.length > 1 ? [Validators.required] : null,
        );

        if (this.email) {
            this.emailFormControl = new FormControl({
                value: this.email,
                disabled: true,
            });
        } else {
            this.emailFormControl = new FormControl(
                this.email,
                [CustomEmailValidator],
                [checkEmailValidator(this.portalService, false, this.slug)],
            );
        }
        this.externalRegistrationForm = new FormGroup({
            email: this.emailFormControl,
            firstName: new FormControl(this.firstName),
            lastName: new FormControl(this.lastName),
            token: new FormControl(this.externalToken),
            externalUserId: new FormControl(this.externalUserId),
            selectedCompany: this.selectedCompany,
            calculator: this.calculator,
        });

        this.externalRegistrationForm.controls.selectedCompany.valueChanges
            .pipe(startWith(0), pairwise(), takeUntilDestroyed(this.destroyRef))
            .subscribe(([prevCompany, nextCompany]: [Company, Company]) => {
                if (prevCompany) {
                    this.externalRegistrationForm.removeControl(
                        'employeeNumber',
                    );
                    this.externalRegistrationForm.removeControl('costCenter');

                    prevCompany?.additional_fields?.forEach((field) => {
                        this.externalRegistrationForm.removeControl(field.key);
                    });
                }

                if (nextCompany) {
                    nextCompany?.additional_fields?.forEach((field) => {
                        const control = new FormControl<string>({
                            value: null,
                            disabled: field.isDisabled,
                        });

                        if (field.type === FieldType.NUMBER) {
                            control.addValidators(Validators.pattern(NUMBER));
                        }

                        if (field.minLength) {
                            control.addValidators(
                                Validators.minLength(field.minLength),
                            );
                        }

                        if (field.maxLength) {
                            control.addValidators(
                                Validators.maxLength(field.maxLength),
                            );
                        }

                        if (field.isUnique) {
                            control.addAsyncValidators(
                                duplicateAttributeValidator(
                                    this.additionalFieldService,
                                    field.key,
                                    nextCompany.slug,
                                ),
                            );
                        }

                        if (field.required) {
                            control.addValidators(Validators.required);
                        }

                        this.externalRegistrationForm.addControl(
                            field.key,
                            control,
                        );

                        for (const key in this.activatedRoute.snapshot
                            .queryParams) {
                            if (field.key === key) {
                                this.externalRegistrationForm
                                    .get(field.key)
                                    .setValue(
                                        this.activatedRoute.snapshot.queryParamMap.get(
                                            key,
                                        ),
                                    );
                            }
                        }
                    });
                }

                this.externalRegistrationForm.updateValueAndValidity();
                this.company = nextCompany;
            });
    }

    private getCookieValueByName(cookieName: string): string {
        return document.cookie
            .split('; ')
            .find((row) => row.startsWith(cookieName))
            ?.split('=')[1];
    }

    public submit(): void {
        this.isSubmitClicked = true;
        const controls = this.externalRegistrationForm.controls;
        const data: AzureRegistrationInfo = {
            calculator: controls.calculator.value ? 1 : 0,
            email: controls.email.getRawValue(),
            first_name: controls.firstName.value,
            last_name: controls.lastName.value,
            company_id: this.selectedCompany?.value?.id,
            token: controls.token.getRawValue(),
        };

        if (controls?.selectedCompany?.value?.additional_fields.length > 0) {
            data.attributes = [];
            controls.selectedCompany.value.additional_fields.forEach(
                (field) => {
                    data.attributes.push({
                        key: field.key,
                        value: this.externalRegistrationForm.get(field.key)
                            .value,
                    });
                },
            );
        }

        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        this.httpClient
            .post(this.actionUrl, { ...data }, { headers })
            .subscribe((result: any) => {
                this.loginUser(result);
            });
    }

    private loginUser(response: LoginAsResponse): void {
        const redirectTo = '/employee';
        const accessToken = 'employee_access_token';

        location.href = this.redirectService.buildRedirectUrl(
            redirectTo,
            accessToken,
            response,
            false,
        );
    }

    public isCivilServant(): boolean {
        return this.civilServant;
    }

    public openPrivacyPolicyDialog(): void {
        this.dialog.open<PrivacyPolicyComponent, PrivacyPolicyData, void>(
            PrivacyPolicyComponent,
            {
                panelClass: 'mat-mdc-dialog-surface--no-padding',
                data: {
                    type: PrivacyPolicyType.PRIVACY_POLICY,
                    slug: this.slug,
                },
            },
        );
    }
}
