import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { GeoService } from '@core/services/mercator/geo.service';
import { CityInterface } from '@models/city.interface';
import { Observable } from 'rxjs';

@Pipe({
    name: 'city',
    pure: false,
})
export class CityPipe implements PipeTransform {
    private cityObserver: Observable<CityInterface>;
    private city = '...';

    constructor(
        private citiesService: GeoService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    public transform(value: number): string {
        if (value && !this.cityObserver) {
            this.cityObserver = this.citiesService.find(value);
            this.cityObserver.subscribe((city) => {
                this.city = city.name;
                this.changeDetector.markForCheck();
            });
        }
        return this.city;
    }
}
