import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'euroCurrency',
})
export class CurrencyEuroPipe extends CurrencyPipe implements PipeTransform {
    public transform(value: string | number): any {
        if (!value) {
            return super.transform(0, 'EUR');
        }
        return super.transform(value, 'EUR');
    }
}
