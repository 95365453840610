<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img
                        [src]="null"
                        onerror="this.style.display='none'"
                        alt=""
                    />
                </div>

                <div class="card">
                    <div class="card-body">
                        <h2 class="title">
                            Authorizing {{ consentVerification.client.name }}
                        </h2>
                        <div class="row justify-content-center">
                            <div class="col-sm-4 mb-3">
                                <img
                                    [alt]="
                                        'client' +
                                        consentVerification.client.name +
                                        'logo'
                                    "
                                    [src]="consentVerification.client.logo"
                                />
                            </div>

                            <p class="col-sm-12">
                                <strong>{{
                                    consentVerification.client.name
                                }}</strong>
                                is asking you access to:
                            </p>
                        </div>

                        <form novalidate>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="alert alert-info grant">
                                        <i
                                            aria-hidden="true"
                                            class="fal fa-user-circle"
                                        ></i>
                                        <div class="grant-text">
                                            Access to your personal information
                                            like E-Mail, name, address...
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-4">
                                <div class="col-12 d-flex gap-3">
                                    <button
                                        type="button"
                                        class="btn btn-light"
                                        [disabled]="submitProcess"
                                        (click)="reject()"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fal fa-sync-alt"
                                            *ngIf="submitProcess"
                                        >
                                        </i>
                                        <span>Reject</span>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [disabled]="submitProcess"
                                        (click)="accept()"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fal fa-sync-alt"
                                            *ngIf="submitProcess"
                                        >
                                        </i>
                                        <span>Accept</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
