import { Component } from '@angular/core';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { Router } from '@angular/router';

@Component({
    selector: 'dd-automatic-logout',
    templateUrl: './automatic-logout.component.html',
})
export class AutomaticLogoutComponent {
    protected readonly module: LoginMode;
    private readonly slug: string;

    constructor(private router: Router) {
        this.module = history.state?.module;
        this.slug = history.state?.slug;
    }

    public navigateToLogin(): void {
        switch (this.module) {
            case 'company':
            case 'employee': {
                const basePath =
                    this.module === 'company' ? 'admin' : 'employee';
                this.router.navigate(['firma', this.slug, basePath, 'login']);
                break;
            }
            case 'supplier':
                this.router.navigate(['lieferanten', 'login']);
                break;
            case 'system':
                this.router.navigate(['system/login']);
                break;
            case 'portal':
            default:
                this.router.navigate(['portal/login']);
                break;
        }
    }
}
