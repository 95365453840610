import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { EMAIL_MATCH } from '@core/constants/regex';

/**
 * @deprecated Use CustomEmailValidator in FormControl
 */
@Directive({
    selector: '[email][formControlName],[email][formControl],[email][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: EmailDirective,
            multi: true,
        },
    ],
})
export class EmailDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const fieldValue = control.value;

        if (fieldValue && !String(fieldValue).match(EMAIL_MATCH)) {
            return {
                email: 'Ungültige E-Mail',
            };
        }

        return null;
    }
}
