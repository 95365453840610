<footer>
    <button
        type="button"
        (click)="openAccessibilityDialog()"
        class="btn btn-link"
        translate
    >
        GENERIC.LABELS.ACCESSIBILITY_DECLARATION
    </button>
    <button
        *ngIf="slug"
        type="button"
        (click)="openPrivacyPolicyDialog()"
        class="btn btn-link"
        translate
    >
        GENERIC.LABELS.PRIVACY_POLICY
    </button>
</footer>
