import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '@companies/companies.service';
import { TranslateService } from '@ngx-translate/core';
import { PortalService } from '@portals/portal.service';

export enum PrivacyPolicyType {
    PRIVACY_POLICY,
    IMPRINT,
    CUSTOM,
}

export type PrivacyPolicyData =
    | {
          type: PrivacyPolicyType.PRIVACY_POLICY;
          slug?: string;
      }
    | {
          type: PrivacyPolicyType.IMPRINT;
      }
    | {
          type: PrivacyPolicyType.CUSTOM;
          title: string;
          text: string;
      };

@Component({
    selector: 'dd-privacy-policy',
    templateUrl: './privacy-policy.component.html',
})
export class PrivacyPolicyComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public title: string;
    public text: string;

    constructor(
        public dialogRef: MatDialogRef<PrivacyPolicyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PrivacyPolicyData,
        private translate: TranslateService,
        private portalService: PortalService,
        private companiesService: CompaniesService,
    ) {}

    public ngOnInit(): void {
        this.loadText();
    }

    private loadText(): void {
        switch (this.data.type) {
            case PrivacyPolicyType.PRIVACY_POLICY:
                this.title = this.translate.instant(
                    'GENERIC.LABELS.PRIVACY_POLICY',
                );
                this.getPrivacyPolicy(this.data.slug);
                break;
            case PrivacyPolicyType.IMPRINT:
                this.title = this.translate.instant('GENERIC.LABELS.IMPRINT');
                this.portalService
                    .getCurrentPortal()
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((portal) => {
                        this.text = portal.imprint;
                    });
                break;
            default:
                this.title = this.translate.instant(this.data.title);
                this.text = this.translate.instant(this.data.text);
                break;
        }
    }

    private getPrivacyPolicy(slug: string): void {
        if (slug) {
            this.companiesService.getPolicy(slug).subscribe((policy) => {
                this.text = policy.policy;
            });
        } else {
            this.portalService
                .getCurrentPortal()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((policy) => {
                    this.text = policy.policy;
                });
        }
    }
}
