import { Pipe, PipeTransform } from '@angular/core';
import { GeoService } from '@core/services/mercator/geo.service';
import { Observable, map } from 'rxjs';

@Pipe({
    name: 'asyncCity',
})
export class AsyncCityPipe implements PipeTransform {
    constructor(private cityService: GeoService) {}

    public transform(value: number): Observable<string> {
        return this.cityService.find(value).pipe(map((city) => city?.name));
    }
}
