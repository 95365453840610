export interface Status {
    id: number;
    label: string;
    icon?: string;
    type?: StatusTypes;
    table?: string;
    order?: number;
    hasTooltip?: boolean;
    tooltip_content?: string;
    sort_value?: number;
}

export enum StatusTypes {
    success = 'success',
    default = 'default',
    danger = 'danger',
    warning = 'warning',
    info = 'info',
    red = 'red',
}
