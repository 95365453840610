import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserRole } from '@app/users/interfaces/user-role.interface';
import { LoginAsResponse } from '@app/users/users.service';
import { ForbiddenError } from '@core/exceptions/error';
import { AuthSettingsService } from '@core/services/auth/auth-settings.service';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { LoadingService } from '@core/services/loading/loading.service';
import { RedirectService } from '@core/services/redirect/redirect.service';
import { TypedFormGroup } from '@core/types/form.type';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityDialogComponent } from '@public/accessibility/accessibility.component';
import { Login } from '@public/interfaces/login.interface';
import {
    CloseDialogData,
    DialogData,
    LoginSelectRoleDialogComponent,
} from '@public/login-select-role-dialog/login-select-role-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

interface HealthState {
    status: string;
    log: {
        status: string;
        message?: string;
        context?: any;
    };
    database: {
        status: string;
        message?: string;
        context?: any;
    };
    env: {
        status: string;
        message?: string;
        context?: any;
    };
}

@Component({
    selector: 'mrc-login-dd',
    templateUrl: './login-dd.component.html',
    styleUrls: ['./login-dd.component.scss'],
})
export class LoginDeutscheDienstradComponent implements OnInit {
    public loginForm = new FormGroup<TypedFormGroup<Login>>({
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(180),
            CustomEmailValidator,
        ]),
        password: new FormControl('', [
            Validators.required,
            Validators.maxLength(60),
        ]),
    });
    public up = true;

    constructor(
        private authSettings: AuthSettingsService,
        private tokenStorage: TokenStorageService,
        private toaster: ToastrService,
        private loadingService: LoadingService,
        private httpClient: HttpClient,
        private redirectService: RedirectService,
        private translateService: TranslateService,
        private dialog: MatDialog,
    ) {}

    public ngOnInit(): void {
        this.loadingService.firstLoading = false;
        this.authSettings.updatePath('/');
        this.checkHealthState();
        this.tokenStorage.clearAll();
    }

    public checkHealthState(): void {
        this.httpClient.get('health').subscribe({
            next: (health: HealthState) => {
                if (health.status === 'OK') {
                    this.up = true;
                } else if (health.database.status !== 'OK') {
                    this.up = false;
                }
            },
            error: () => {
                this.up = false;
            },
        });
    }

    public login(): void {
        if (this.loginForm.disabled) return;

        this.loginForm.updateValueAndValidity();

        if (this.loginForm.invalid) return;

        this.loginForm.disable();
        const data = this.loginForm.getRawValue();
        this.httpClient
            .post('unilogin', data)
            .pipe(
                catchError((error) => {
                    this.loginForm.enable();
                    if (error instanceof ForbiddenError) {
                        this.toaster.error(
                            this.translateService.instant(error.message),
                        );
                    } else {
                        this.toaster.error(error.error.message);
                    }
                    return throwError(() => error);
                }),
            )
            .subscribe((response: LoginAsResponse) => {
                const user = response.user;
                if (user.roles?.length === 1) {
                    this.handleLoginByRole(user.roles[0], response);
                    this.loginForm.enable();
                } else {
                    this.dialog
                        .open<
                            LoginSelectRoleDialogComponent,
                            DialogData,
                            CloseDialogData
                        >(LoginSelectRoleDialogComponent, {
                            data: { roles: user.roles },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (result?.role) {
                                this.handleLoginByRole(result.role, response);
                            }
                            this.loginForm.enable();
                        });
                }
            });
    }

    public handleLoginByRole(role: UserRole, response: LoginAsResponse): void {
        let redirectTo: string;
        let accessToken: string;

        switch (role.name) {
            case 'Company Admin':
                redirectTo = '/firma';
                accessToken = 'company_access_token';
                break;
            case 'Employee':
                redirectTo = '/employee';
                accessToken = 'employee_access_token';
                break;
            case 'Supplier Admin':
                redirectTo = '/lieferanten';
                accessToken = 'supplier_access_token';
                break;
            case 'Portal Admin':
                redirectTo = '/portal';
                accessToken = 'access_token';
                break;
        }

        location.href = this.redirectService.buildRedirectUrl(
            redirectTo,
            accessToken,
            response,
            false,
            role,
        );
    }

    public isWeihnachten(): boolean {
        const today = new Date();
        return today.getMonth() + 1 === 12;
    }

    public openAccessibilityDialog(): void {
        this.dialog.open(AccessibilityDialogComponent, {
            panelClass: 'mat-mdc-dialog-surface--no-padding',
        });
    }
}
