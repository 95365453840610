import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { MODULE } from '@core/tokens/module.token';
import { Status } from '@models/status';

@Component({
    selector: 'mrc-status-tabs',
    templateUrl: './status-tabs.component.html',
    styleUrls: [
        './status-tabs.component.scss',
        '../../components/status/status.component.scss',
    ],
})
export class StatusTabsComponent implements OnChanges {
    @Input() public statusList: Status[];
    @Input() public showIcon: boolean;
    @Input() public forcedStatus: Status;
    @Output() public selectedStatus = new EventEmitter<Status>();
    public selectedTabIndex = 4;
    public selectedIndexTabMobile = 4;

    constructor(@Inject(MODULE) private module: LoginMode) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.isCompany() && changes.forcedStatus?.currentValue?.length) {
            this.changedTabList(changes.forcedStatus.currentValue[0].label);
            const selectedIndex = this.statusList.findIndex(
                (t) => t.label === changes.forcedStatus.currentValue[0].label,
            );
            this.selectedTabIndex = 0;
            setTimeout(() => {
                this.selectedTabIndex = selectedIndex + 1;
            });
        }
    }

    public changedTab(tabChanged: MatTabChangeEvent): void {
        this.changedTabList(tabChanged.tab.textLabel);
    }

    private changedTabList(label: string): void {
        const selectedStatus = this.statusList.find((t) => t.label === label);
        this.selectedStatus.emit(selectedStatus);
    }

    public changedTabMobile(label: string, index: number): void {
        this.selectedIndexTabMobile = index;
        this.changedTabList(label);
    }

    public isCompany(): boolean {
        return this.module === 'company';
    }
}
