import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MODULE } from '@core/tokens/module.token';

@Component({
    selector: 'dd-select-beamten-status',
    templateUrl: './select-beamten-status.component.html',
})
export class SelectBeamtenStatusComponent implements OnInit {
    @Input() public beamtenControl: FormControl<boolean> = new FormControl();
    // Holds information if company has enabled show_beamte_calculator, in this case the company is a public authority
    // which differentiate between normal employees and civil servants.
    @Input() public publicAuthority = false;
    // Holds the value for the concrete user who accesses the component if the setting calculator is enabled or not.
    // In case of registration is always false because there is no user yet.
    @Input() public userIsCivilServant = false;

    // Resolves if we are in context of employee and public authority.
    public companyBeamte = false;

    constructor(@Inject(MODULE) private module: string) {}

    public ngOnInit(): void {
        if (this.isEmployee() && this.publicAuthority) {
            this.companyBeamte = true;
            this.beamtenControl.setValue(this.userIsCivilServant);
        }
    }

    public isEmployee(): boolean {
        return this.module === 'employee';
    }

    protected readonly Validators = Validators;
}
