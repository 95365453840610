export function Mixin<T>(...mixins): new () => T {
    class MixinAbstractType {
        constructor() {
            mixins.forEach((mixin) => Object.assign(this, new mixin()));
        }
    }
    mixins.forEach((mixin) => {
        Object.getOwnPropertyNames(mixin.prototype).forEach((name) => {
            Object.defineProperty(
                MixinAbstractType.prototype,
                name,
                Object.getOwnPropertyDescriptor(mixin.prototype, name) ||
                    Object.create(null)
            );
        });
    });
    return <any>MixinAbstractType;
}
