import { Component, Input } from '@angular/core';
import { Audit } from '../../shared/models/audit';

@Component({
    selector: 'mrc-audits-detail',
    templateUrl: './audits-detail.component.html',
    styleUrls: ['./audits-detail.component.scss'],
})
export class AuditsDetailComponent {
    @Input() public audits: Audit[];
}
