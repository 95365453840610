<main class="layout-unauthorized">
    <div class="container">
        <div class="main-content row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div
                    *ngIf="allow_public_registration"
                    class="card"
                >
                    <div class="card-body">
                        <div class="logo">
                            <img
                                [src]="logoService.loginLogo"
                                alt=""
                            />
                        </div>
                        <h1
                            class="title"
                            translate
                        >
                            REGISTRATION.TITLE
                        </h1>

                        <div class="row justify-content-center">
                            <form
                                (ngSubmit)="register()"
                                ddFocusInvalidInput
                                [formGroup]="registrationForm"
                                class="col-sm-10"
                            >
                                <p translate>DYNAMIC_FORM.MANDATORY_FIELD</p>
                                <div class="form-group">
                                    <div class="text-center">
                                        <small
                                            *ngIf="childCompanies?.length > 1"
                                            translate
                                        >
                                            REGISTRATION.REGISTER_ON_THE_RIGHT_COMPANY
                                        </small>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                *ngIf="
                                                    childCompanies?.length > 1
                                                "
                                                [control]="
                                                    registrationForm.controls
                                                        .selectedCompany
                                                "
                                                [label]="
                                                    ('REGISTRATION.I_AM_EMPLOYED_IN_THIS_COMPANY'
                                                        | translate) + ':'
                                                "
                                                [id]="'companySelection'"
                                            >
                                                <ng-select
                                                    labelForId="companySelection"
                                                    [clearable]="false"
                                                    [items]="childCompanies"
                                                    [searchable]="false"
                                                    bindLabel="name"
                                                    class="form-control"
                                                    formControlName="selectedCompany"
                                                    [notFoundText]="
                                                        'REGISTRATION.NO_COMPANY_FOUND'
                                                            | translate
                                                    "
                                                    [placeholder]="
                                                        'REGISTRATION.PLEASE_ENTER_YOUR_COMPANY_LOCATION'
                                                            | translate
                                                    "
                                                ></ng-select>
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <dd-select-beamten-status
                                                *ngIf="
                                                    company?.show_beamte_calculator
                                                "
                                                [beamtenControl]="
                                                    registrationForm.controls
                                                        .calculator
                                                "
                                                [publicAuthority]="
                                                    company?.show_beamte_calculator
                                                "
                                            ></dd-select-beamten-status>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                [control]="
                                                    registrationForm.controls
                                                        ?.email
                                                "
                                                [id]="'email'"
                                                [label]="
                                                    'USER.EMAIL' | translate
                                                "
                                            >
                                                <input
                                                    class="form-control form-control-bg"
                                                    formControlName="email"
                                                    id="email"
                                                    name="email"
                                                    data-testid="email"
                                                    [placeholder]="
                                                        'USER.EMAIL' | translate
                                                    "
                                                    autocomplete="email"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <mrc-form-item-field
                                                [control]="
                                                    registrationForm.controls
                                                        ?.firstName
                                                "
                                                [id]="'first_name'"
                                                class="col-6"
                                                [label]="
                                                    'USER.FIRSTNAME' | translate
                                                "
                                            >
                                                <input
                                                    class="form-control form-control-bg"
                                                    formControlName="firstName"
                                                    id="first_name"
                                                    name="first_name"
                                                    [placeholder]="
                                                        'USER.FIRSTNAME'
                                                            | translate
                                                    "
                                                    autocomplete="given-name"
                                                    data-testid="firstName"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                        <div class="col-6">
                                            <mrc-form-item-field
                                                [control]="
                                                    registrationForm.controls
                                                        ?.lastName
                                                "
                                                [id]="'last_name'"
                                                class="col-6"
                                                [label]="
                                                    'USER.LASTNAME' | translate
                                                "
                                            >
                                                <input
                                                    class="form-control form-control-bg"
                                                    formControlName="lastName"
                                                    id="last_name"
                                                    name="last_name"
                                                    [placeholder]="
                                                        'USER.LASTNAME'
                                                            | translate
                                                    "
                                                    autocomplete="family-name"
                                                    data-testid="lastName"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                [id]="'employeeNumber'"
                                                *ngIf="
                                                    company?.required_personal_number
                                                "
                                                [control]="
                                                    registrationForm.controls
                                                        ?.employeeNumber
                                                "
                                                class="mb-0"
                                                [label]="
                                                    'USER.STAFF_ID' | translate
                                                "
                                            >
                                                <input
                                                    id="employeeNumber"
                                                    class="form-control form-control-bg"
                                                    formControlName="employeeNumber"
                                                    name="employee_number"
                                                    [placeholder]="
                                                        'USER.STAFF_ID'
                                                            | translate
                                                    "
                                                    type="text"
                                                    autocomplete="off"
                                                    data-testid="employeeNumber"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                [id]="'costCenter'"
                                                *ngIf="
                                                    company?.required_cost_centre
                                                "
                                                [control]="
                                                    registrationForm.controls
                                                        ?.costCenter
                                                "
                                                class="mb-0"
                                                [label]="
                                                    ('USER.COST_CENTER'
                                                        | translate) + ':'
                                                "
                                            >
                                                <input
                                                    id="costCenter"
                                                    class="form-control form-control-bg"
                                                    formControlName="costCenter"
                                                    name="cost_centre"
                                                    [placeholder]="
                                                        'USER.COST_CENTER'
                                                            | translate
                                                    "
                                                    type="text"
                                                    data-testid="costCenter"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        *ngFor="
                                            let field of company?.additional_fields
                                        "
                                    >
                                        <div class="col">
                                            <dd-additional-field
                                                [field]="field"
                                                [control]="
                                                    registrationForm.controls[
                                                        field.key
                                                    ]
                                                "
                                            ></dd-additional-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                [control]="
                                                    registrationForm.controls
                                                        ?.password
                                                "
                                                [id]="'password'"
                                                [label]="
                                                    'LOGIN.PASSWORD' | translate
                                                "
                                            >
                                                <input
                                                    class="form-control form-control-bg"
                                                    formControlName="password"
                                                    id="password"
                                                    name="password"
                                                    [placeholder]="
                                                        'LOGIN.PASSWORD'
                                                            | translate
                                                    "
                                                    reverse="true"
                                                    type="password"
                                                    validateEqual="passwordConfirmation"
                                                    autocomplete="new-password"
                                                    data-testid="password"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mrc-form-item-field
                                                [control]="
                                                    registrationForm.controls
                                                        ?.passwordConfirmation
                                                "
                                                [id]="'password-confirmation'"
                                                [label]="
                                                    'PASSWORD.PASSWORD_REPEAT'
                                                        | translate
                                                "
                                            >
                                                <input
                                                    id="password-confirmation"
                                                    [attr.aria-label]="
                                                        'PASSWORD.PASSWORD_REPEAT'
                                                            | translate
                                                    "
                                                    class="form-control form-control-bg"
                                                    formControlName="passwordConfirmation"
                                                    name="password_confirmation"
                                                    [placeholder]="
                                                        'PASSWORD.PASSWORD_REPEAT'
                                                            | translate
                                                    "
                                                    type="password"
                                                    validateEqual="password"
                                                    autocomplete="new-password"
                                                    data-testid="passwordConfirmation"
                                                />
                                            </mrc-form-item-field>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        [attr.aria-label]="
                                            'REGISTRATION.SHOW_PRIVACY_POLICY'
                                                | translate
                                        "
                                        (click)="openPrivacyPolicyDialog()"
                                        class="btn btn-link p-0"
                                        translate
                                    >
                                        REGISTRATION.PRIVACY_POLICY
                                    </button>
                                    <div class="row">
                                        <div class="d-grid">
                                            <button
                                                type="submit"
                                                class="btn btn-primary form-group-btn"
                                                translate
                                                data-testid="submitRegistration"
                                            >
                                                REGISTRATION.SUBMIT_REGISTRATION
                                            </button>
                                        </div>
                                    </div>
                                    <nav>
                                        <div
                                            class="row mt-2 justify-content-center"
                                        >
                                            <p class="mb-0">
                                                <span
                                                    translate
                                                    aria-hidden="true"
                                                    >REGISTRATION.ALREADY_REGISTERED</span
                                                >
                                                <a
                                                    [routerLink]="'../login'"
                                                    class="btn btn-link p-0 ms-1"
                                                    translate
                                                    [attr.aria-label]="
                                                        'REGISTRATION.NAV_TO_LOGIN_PAGE'
                                                            | translate
                                                    "
                                                >
                                                    REGISTRATION.HERE
                                                </a>
                                            </p>
                                        </div>
                                        <div
                                            class="row mt-3 justify-content-center"
                                        >
                                            <a
                                                [routerLink]="
                                                    '../password/forgot-password'
                                                "
                                                class="btn btn-link"
                                                translate
                                                >LOGIN.FORGOT_PASSWORD</a
                                            >
                                        </div>
                                    </nav>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="!allow_public_registration"
                    class="card"
                >
                    <div class="card-body">
                        <div class="logo">
                            <img
                                [src]="logoService.loginLogo"
                                [alt]="'COMPANY.LOGO' | translate"
                            />
                        </div>
                        <h1
                            class="title"
                            translate
                        >
                            REGISTRATION.PUBLIC_REGISTRATION_IS_DISABLED
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<dd-public-footer></dd-public-footer>
