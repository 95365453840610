<div class="d-flex align-items-center">
    <span class="text">Zeige</span>
    <div class="form-control-holder">
        <select
            (change)="changePageSize()"
            [(ngModel)]="pageSize"
            class="page-size"
        >
            <option
                *ngFor="let size of pager"
                [value]="size"
            >
                {{ size }}
            </option>
        </select>
    </div>
    <span class="text">Zeilen</span>
</div>
