import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    public readonly loadingObservable: BehaviorSubject<boolean> =
        new BehaviorSubject(false);

    private queue: HttpRequest<any>[] = [];

    public firstLoading = true;

    public queueRequest(req: HttpRequest<any>): void {
        if (!this.loadingObservable.getValue()) {
            this.loadingObservable.next(true);
        }
        this.queue.push(req);
    }

    public removeFromQueue(req: HttpRequest<any>): BehaviorSubject<boolean> {
        this.queue.splice(this.queue.indexOf(req), 1);
        if (!this.queue.length) {
            this.loadingObservable.next(false);
            this.firstLoading = false;
        }
        return this.loadingObservable;
    }
}
