import { Directive, HostListener } from '@angular/core';
import { OffersService } from '@app/offers/offers.service';

@Directive({
    selector: '[mrcDownloadAssessors]',
})
export class DownloadAssessorsDirective {
    constructor(private readonly offersService: OffersService) {}

    @HostListener('click', ['$event'])
    public onClick(event): void {
        if (event.target.className === 'download-assessor') {
            event.preventDefault();
            this.offersService.downloadLeasingablesPdf();
        }
    }
}
