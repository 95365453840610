export function saveAs(data: any, name: string): void {
    const a = document.createElement('a');
    a.download = name || data.name || 'download';
    a.rel = 'noopener';
    if (typeof data === 'string') {
        a.href = URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
    } else {
        a.href = URL.createObjectURL(data);
    }
    a.click();
}
