<div class="add-field-modal">
    <div class="dialog-close">
        <button
            type="button"
            class="btn-close"
            [aria-label]="'GENERIC.ACTIONS.CLOSE' | translate"
            mat-dialog-close
        ></button>
    </div>
    <h2
        matDialogTitle
        translate
    >
        GENERIC.LABELS.ADD_FIELD
    </h2>
    <form
        [formGroup]="addFieldForm"
        (ngSubmit)="add()"
    >
        <mat-dialog-content>
            <div class="add-field-modal__input">
                <mrc-form-item-field
                    [id]="'label'"
                    [label]="'GENERIC.LABELS.LABEL' | translate"
                    [control]="addFieldForm.controls.label"
                >
                    <input
                        id="label"
                        class="form-control"
                        type="text"
                        formControlName="label"
                    />
                </mrc-form-item-field>
            </div>
            <div class="add-field-modal__input">
                <mrc-form-item-field
                    [label]="'ADDITIONAL_FIELDS.TYPE' | translate"
                    [id]="'type'"
                    [control]="addFieldForm.controls.type"
                >
                    <ng-select
                        labelForId="type"
                        class="form-control"
                        [items]="typeItems"
                        bindValue="id"
                        formControlName="type"
                        [clearable]="false"
                        [searchable]="false"
                    >
                        <ng-template
                            ng-label-tmp
                            ng-option-tmp
                            let-item="item"
                        >
                            {{ item.label | translate }}
                        </ng-template>
                    </ng-select>
                </mrc-form-item-field>
            </div>
            <div
                *ngIf="showOptionsControl()"
                class="add-field-modal__input"
            >
                <h3 translate>GENERIC.LABELS.OPTIONS</h3>
                <button
                    type="button"
                    class="btn btn-light"
                    (click)="addOption()"
                    [attr.aria-label]="
                        'GENERIC.ACTIONS.ADD_OPTIONS' | translate
                    "
                >
                    <i
                        aria-hidden="true"
                        class="fal fa-plus"
                    ></i>
                </button>
                <ul
                    class="list-unstyled add-field-modal__input__option__wrapper"
                >
                    <li
                        *ngFor="
                            let control of options.controls;
                            let idx = index;
                            let isFirst = first
                        "
                        class="add-field-modal__input__option"
                    >
                        <div class="input-group">
                            <input
                                class="form-control"
                                [attr.aria-label]="
                                    'GENERIC.LABELS.INPUT_OPTION' | translate
                                "
                                type="text"
                                [formControl]="control"
                            />
                            <button
                                *ngIf="!isFirst"
                                type="button"
                                class="btn btn-light input-group-text"
                                (click)="removeOption(idx)"
                                [attr.aria-label]="
                                    'GENERIC.ACTIONS.DELETE_OPTION' | translate
                                "
                            >
                                <i
                                    aria-hidden="true"
                                    class="fal fa-trash"
                                ></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div
                class="row mb-3"
                *ngIf="showMinMax()"
            >
                <div class="col-12 col-sm-6">
                    <div class="add-field-modal__input">
                        <mrc-form-item-field
                            [id]="'minLength'"
                            [label]="'GENERIC.LABELS.MIN_LENGTH' | translate"
                            [control]="addFieldForm.controls.minLength"
                        >
                            <input
                                id="minLength"
                                class="form-control"
                                type="number"
                                formControlName="minLength"
                            />
                        </mrc-form-item-field>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="add-field-modal__input">
                        <mrc-form-item-field
                            [id]="'maxLength'"
                            [label]="'GENERIC.LABELS.MAX_LENGTH' | translate"
                            [control]="addFieldForm.controls.maxLength"
                        >
                            <input
                                id="maxLength"
                                class="form-control"
                                type="number"
                                formControlName="maxLength"
                            />
                        </mrc-form-item-field>
                    </div>
                </div>
            </div>
            <div class="add-field-modal__input">
                <mrc-form-item-field
                    [id]="'key'"
                    [label]="'GENERIC.LABELS.KEY' | translate"
                    [control]="addFieldForm.controls.key"
                >
                    <input
                        id="key"
                        class="form-control"
                        type="text"
                        formControlName="key"
                    />
                </mrc-form-item-field>
            </div>
            <div class="add-field-modal__input">
                <mrc-form-item-field
                    [id]="'external-key'"
                    [label]="'GENERIC.LABELS.EXTERNAL_KEY' | translate"
                    [control]="addFieldForm.controls.external_key"
                >
                    <input
                        class="form-control"
                        formControlName="external_key"
                        id="external-key"
                        type="text"
                    />
                </mrc-form-item-field>
            </div>
            <div class="add-field-modal__input">
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="isUnique"
                    >{{ 'GENERIC.LABELS.UNIQUE' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="required"
                    >{{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="isGlobalField"
                    >{{
                        'ADDITIONAL_FIELDS.FOR_ALL_CONNECTED_COMPANIES'
                            | translate
                    }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="enable_api"
                    translate
                    >{{ 'ADDITIONAL_FIELDS.IN_INTERFACE' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="enable_uev"
                    >{{ 'ADDITIONAL_FIELDS.CONTRACT_REPLACEMENT' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="enable_email"
                    >{{ 'ADDITIONAL_FIELDS.IN_EMAILS' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    class="add-field-modal__input__checkbox"
                    formControlName="disabled"
                    >{{ 'GENERIC.LABELS.DISABLED' | translate }}
                </mat-checkbox>
            </div>
            <div class="add-field-modal__input">
                <mrc-form-item-field
                    [id]="'placeholder'"
                    [label]="'ADDITIONAL_FIELDS.PLACEHOLDER' | translate"
                    [control]="addFieldForm.controls.placeholder"
                >
                    <input
                        id="placeholder"
                        class="form-control"
                        type="text"
                        formControlName="placeholder"
                    />
                </mrc-form-item-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button
                type="button"
                class="btn btn-outline-dark me-3"
                (click)="close()"
                translate
            >
                GENERIC.ACTIONS.CANCEL
            </button>
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="addFieldForm.invalid"
                translate
            >
                GENERIC.ACTIONS.ADD
            </button>
        </mat-dialog-actions>
    </form>
</div>
