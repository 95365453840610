import { PermissionModel } from './permission.model';
import { User } from './user';

export class CompanyEditsPermissions {
    public admins: User[];

    public editPermissions(user: User, permissions: PermissionModel[]): void {
        if (permissions.length === 0) {
            this.admins = [...this.admins];
            this.admins.splice(
                this.admins.findIndex((item) => item.id === user.id),
                1,
            );
        } else {
            const admin = this.admins.find((item) => item.id === user.id);
            if (admin) {
                admin.permissions = [...permissions];
            } else {
                this.admins = [
                    ...this.admins,
                    Object.assign(user, { permissions }),
                ];
            }
        }
    }
}
