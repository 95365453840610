import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
    selector: 'mrc-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    exportAs: 'confirmModal',
})
export class ConfirmModalComponent<T> {
    @Input() public title: string;
    @Input() public icon: string;
    @Input() public class: string;
    @Input() public acceptLabel: string;
    @Input() public cancelLabel: string;
    @Input() public autoclose = true;

    @ViewChild('template') public template;

    @Output() public accepted: EventEmitter<T> = new EventEmitter();
    @Output() public rejected: EventEmitter<T> = new EventEmitter();

    public subject: T;
    public source: string | number;
    private openedModal: BsModalRef;

    constructor(public modalService: BsModalService) {}

    public confirm(subject: T, source?: string | number): void {
        this.source = source;
        this.openedModal = this.modalService.show(this.template, <ModalOptions>{
            class: this.class,
            animated: false,
        });
        this.subject = subject;
    }

    public accept(): void {
        this.accepted.emit(this.subject);
        if (this.autoclose) {
            this.openedModal.hide();
        }
    }

    public reject(): void {
        this.rejected.emit(this.subject);
        this.openedModal.hide();
    }
}
