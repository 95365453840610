import { Model } from '@shared/models/model';
import { Relation, RelationType } from './relation';

export function HasMany(type, field) {
    return (target): void => {
        const currentRelations = Model.relations.get(target);
        if (currentRelations) {
            Model.relations.set(target, [
                ...currentRelations,
                new Relation(type, field, RelationType.HasMany),
            ]);
        } else {
            Model.relations.set(target, [
                new Relation(type, field, RelationType.HasMany),
            ]);
        }
    };
}
