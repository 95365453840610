import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from '@shared/models/profile';
import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    catchError,
    map,
    shareReplay,
    tap,
    throwError,
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private profileObservable: Observable<Profile>;
    private version: string;

    public global_view = false;

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService,
        private toasterService: ToastrService,
        private translateService: TranslateService,
    ) {
        this.global_view =
            this.tokenStorage.getAccessTokenSync('Global-View') === 'true';
    }

    public getProfile(): Observable<Profile> {
        if (!this.profileObservable) {
            this.profileObservable = this.http
                .get<ResponseGeneric<Profile>>('profile')
                .pipe(
                    map(({ payload }) => new Profile(payload)),
                    tap((profile) => {
                        if (!this.version) {
                            this.version = profile.version;
                        } else if (this.version !== profile.version) {
                            location.reload();
                        }

                        if (profile.language) {
                            localStorage.setItem('language', profile.language);
                            this.translateService.use(profile.language);
                        }
                    }),
                    catchError((error) => {
                        this.clearCache();
                        this.toasterService.error(error);
                        return throwError(() => error);
                    }),
                    shareReplay(1),
                );
        }

        return this.profileObservable;
    }

    public getFreshProfile(): Observable<Profile> {
        this.clearCache();
        return this.getProfile();
    }

    public getProfileById(id: number): Observable<Profile> {
        return this.http
            .get<ResponseGeneric<Profile>>(`profile/${id}`)
            .pipe(map(({ payload }) => new Profile(payload)));
    }

    public updatePolicy(profile: Profile): Observable<unknown> {
        return this.http.put('profile', profile);
    }

    public clearCache(): void {
        this.profileObservable = null;
    }

    public updateProfile(profile: Profile): Observable<Profile> {
        if (profile.isSsoUser) {
            profile.email = undefined;
        }
        return this.http
            .post<ResponseGeneric<Profile>>('profile', profile)
            .pipe(
                map((result) => result.payload),
                tap(() => this.clearCache()),
                catchError((error) => {
                    this.toasterService.error(
                        'Das Benutzerprofil konnte nicht aktualisiert werden',
                    );
                    return throwError(() => error);
                }),
            );
    }
}
