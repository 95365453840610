import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TitleResolver } from '@core/services/title/title-resolver.service';
import { AutomaticLogoutComponent } from '@public/automatic-logout/automatic-logout.component';
import { protectedGuard } from 'ngx-auth';
import { ErrorPageComponent } from '../public/error-page/error-page.component';
import { ForgotPasswordComponent } from '../public/forgot-password/forgot-password.component';
import { LoginDeutscheDienstradComponent } from '../public/login-dd/login-dd.component';
import { LoginComponent } from '../public/login/login.component';
import { ResetPasswordComponent } from '../public/reset-password/reset-password.component';

const routes: Routes = [
    {
        path: 'admin',
        canActivate: [protectedGuard],
        loadChildren: () =>
            import('./admin/system-admin.module').then(
                (m) => m.SystemAdminModule,
            ),
        data: { preload: false },
    },
    {
        path: 'login',
        component: LoginComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.LOGIN',
        },
    },
    {
        path: 'unilogin',
        component: LoginDeutscheDienstradComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.LOGIN',
        },
    },
    {
        path: 'password',
        children: [
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.RESET_PASSWORD',
                },
            },
            {
                path: 'reset',
                component: ResetPasswordComponent,
                title: TitleResolver,
                data: {
                    title: 'ROUTE_TITLES.RESET_PASSWORD',
                },
            },
        ],
    },
    {
        path: 'automatic-logout',
        component: AutomaticLogoutComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.LOGOUT',
        },
    },
    {
        path: '',
        redirectTo: '/admin/dashboard',
        pathMatch: 'prefix',
    },
    {
        path: '**',
        redirectTo: '/error/404?message=Nicht gefunden',
    },
    {
        path: 'error/:errorCode',
        loadChildren: () => ErrorPageComponent,
        title: TitleResolver,
        data: {
            title: 'ROUTE_TITLES.ERROR',
        },
    },
];

export const SystemAdminRoutes = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
});
