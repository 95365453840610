import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdditionalField } from '@app/additional-fields/interfaces/additional-field';
import { CoverageType } from '@app/individual-settings/enums/coverage-type';
import { OfferAccessoryCategory } from '@app/offers/interfaces/offer-accessory-category.interface';
import { FILENAME_MATCH, FILENAME_REPLACE2 } from '@core/constants/regex';
import { saveAs } from '@core/helpers/file';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { UsersFactory } from '@core/services/mercator/users.factory';
import { ProfileService } from '@core/services/profile/profile.service';
import { Factory } from '@shared/factory';
import { Company, GrossConversion, InvoiceType } from '@shared/models/company';
import { Document } from '@shared/models/document';
import { User } from '@shared/models/user';
import { ToastrService } from 'ngx-toastr';
import {
    BehaviorSubject,
    Observable,
    catchError,
    map,
    take,
    tap,
    throwError,
} from 'rxjs';
import { CompaniesFactory } from './companies.factory';
import { CompanyPolicy } from './interfaces/company-policy';
import { ValueExists } from './interfaces/value-exists';
import { TransferContractSummaryType } from './types/transfer-contract-summary.type';

interface ExportSettings {
    format: string;
    target: string;
    filter: { role: string; user_role_id: number };
    exportChildCompanies: boolean;
}

interface CreateCompanyPayload {
    isActive: boolean;
    parent_company_id: number;
    meta?: {
        address: string;
        city_id: number;
        enable_bikesde: boolean;
        end_contract: string;
        logo: string;
        name: string;
        phone: string;
        show_beamte_calculator: boolean;
        slug: string;
        total_employees: number;
        total_employees_authorized: number;
        vip_customer: boolean;
        gross_conversion: GrossConversion;
        zip: string;
        send_notification_emails: boolean;
    };
    leasing?: {
        bank_id: number;
        boni_number: string;
        gp_number: string;
        is_gp_number_unique_check_active: boolean;
        is_prv_number_unique_check_active: boolean;
        prv_number: string;
        invoice_type: InvoiceType;
        vat: string;
        leasing_budget: number;
        elv_els_limit: number;
        is_max_user_amount_uvp: boolean;
        factor: number;
        residualValueCustomer: number;
        residualValueImputed: number;
        insuranceAmount: number;
        serviceBasicEnabled: boolean;
        serviceBasicAmount: number;
        serviceBasicMinimum: number;
        servicePremiumEnabled: boolean;
        servicePremiumAmount: number;
        servicePremiumMinimum: number;
    };
    subsidy?: {
        include_insurance_rate: boolean;
        include_service_rate: boolean;
        max_user_contracts: number;
        min_user_amount: number;
        max_user_amount: number;
        leasing?: {
            subsidy: boolean;
            type: CoverageType;
            amount: number;
            blocked: boolean;
        };
        service?: {
            subsidy: boolean;
            type: CoverageType;
            amount: number;
            blocked: boolean;
        };
        insurance?: {
            subsidy: boolean;
            type: CoverageType;
            amount: number;
            blocked: boolean;
        };
        max_conversion_amount: number;
    };
    contact?: {
        sales_consultant_id: number;
        onboarding_consultant_id: number;
        ddberater_id: number;
        initialAdmins: {
            firstName: string;
            lastName: string;
            email: string;
            phone?: string;
        }[];
    };
    register?: {
        allow_public_registration: boolean;
        enable_employee_registration: boolean;
        required_personal_number: boolean;
        required_cost_centre: boolean;
        required_register_by_domain: boolean;
        mailBlacklist: string[];
        questions: { state: boolean; text: string }[];
        additionalFields: AdditionalField[];
    };
    transferContract?: {
        offer_contract_file_auto_approve: boolean;
        enable_digital_signature: boolean;
        is_accept_employee: boolean;
        transfer_contract_summary: {
            [key in TransferContractSummaryType]: boolean;
        };
    };
    employeeHomepage?: {
        description: string;
        max_user_amount_text: string;
    };
    supplierIds?: number[];
    product_category_ids?: number[];
    s_pedelec_disable?: boolean;
    offerAccessoryCategories?: OfferAccessoryCategory[];
    distributors?: {
        enable_partner: boolean;
        provision_mode: CoverageType;
        netto_provision: number;
        ddpartner_id: number;
        ddpartner_name_id: number;
    };
}

@Injectable({
    providedIn: 'root',
})
export class CompaniesService {
    public _companyData$: BehaviorSubject<Company> =
        new BehaviorSubject<Company>(null);

    public _childCompanies$: BehaviorSubject<Company[]> = new BehaviorSubject<
        Company[]
    >(null);

    public _companySlug$: BehaviorSubject<string> = new BehaviorSubject<string>(
        null,
    );

    private childCompanies: Company[] = null;

    private companySlug: string;

    constructor(
        private httpClient: HttpClient,
        private companyFactory: CompaniesFactory,
        private toaster: ToastrService,
        private userFactory: UsersFactory,
        public profileService: ProfileService,
    ) {}

    // This method loads once intially all relevant company data for interaction with
    // current user
    public loadCompanyDataBySlug(slug: string): void {
        // Already holds the data
        if (slug === this.companySlug) {
            return;
        }
        // The slug in the route is an unique identifier for company provided in url
        // with this we can call the api initially for current company data
        this.getBySlug(slug)
            .pipe(take(1))
            .subscribe((company: Company) => {
                this.companySlug = company.slug;
                this._companyData$.next(company);
                this._companySlug$.next(company.slug);

                // If we do not have a parent id, it would be possible, that the company itself would be a mother
                // company of other companies. So we have to query potential child companies
                if (!company.parent_company_id) {
                    this.childs(company.slug)
                        .pipe(take(1))
                        .subscribe((childCompanies: Company[]) => {
                            this.childCompanies = childCompanies;
                            this._childCompanies$.next(this.childCompanies);
                        });
                }
            });
    }

    public all(
        portalId: number,
        parentCompanyId?: number,
        slim?: boolean,
    ): Observable<Company[]> {
        let params = new HttpParams();
        if (portalId) {
            params = params.append('portal_id', portalId);
        }
        if (parentCompanyId) {
            params = params.append('parent_company_id', parentCompanyId);
        }

        if (slim != null) {
            params = params.append('slim', slim);
        }
        return this.httpClient
            .get<Company[]>('companies/all', { params })
            .pipe(
                map((companies) => new Factory(Company).fromArray(companies)),
            );
    }

    public allSelectableChildren(companyId: number): Observable<Company[]> {
        return this.httpClient.get<Company[]>(
            `companies/${companyId}/allSelectableChilds`,
        );
    }

    public allForSupplier(): Observable<Company[]> {
        const url = 'companies/allForSupplier';
        return this.httpClient
            .get<Company[]>(url)
            .pipe(
                map((companies) => new Factory(Company).fromArray(companies)),
            );
    }

    public childs(parentCompanySlug: string): Observable<Company[]> {
        const options = {
            params: new HttpParams().set(
                'parentCompanySlug',
                parentCompanySlug,
            ),
        };
        return this.httpClient
            .get<Company[]>('companies/childs', options)
            .pipe(
                map((companies) => new Factory(Company).fromArray(companies)),
            );
    }

    public childsAndParents(
        slug: string,
        email?: string,
    ): Observable<Company[]> {
        const url = `companies/childsAndParents/${slug}`;
        let params = new HttpParams();
        if (email) {
            params = params.append('searchEmail', email);
        }
        return this.httpClient
            .get<Company[]>(url, { params })
            .pipe(
                map((companies) => new Factory(Company).fromArray(companies)),
            );
    }

    public allCompanies(): Observable<Company[]> {
        const url = 'companies/allCompanies';
        return this.httpClient
            .get<Company[]>(url)
            .pipe(
                map((companies) => new Factory(Company).fromArray(companies)),
            );
    }

    public get(id: number | string): Observable<Company> {
        if (id === undefined) {
            return;
        }
        return this.httpClient
            .get<Company>(`companies/${id}`)
            .pipe(map((responseBody) => this.parseResponse(responseBody)));
    }

    public getSingle(id: number): Observable<Company> {
        return this.httpClient
            .get(`companies/single/${id}`)
            .pipe(map((item) => new Factory(Company).fromObject(item)));
    }

    // accessing and distributing company data should work for future with the behavioursubject
    // therefore making method private from now on
    private getBySlug(slug: string): Observable<Company> {
        return this.httpClient
            .get(`companies/slug/${slug}`)
            .pipe(map((company) => new Factory(Company).fromObject(company)));
    }

    public checkSlug(slug: string): Observable<Company> {
        return this.httpClient
            .get<{
                payload: { result: Company };
            }>(`companies/slug-exists?slug=${slug}`)
            .pipe(map((response) => response.payload.result));
    }

    public getPaginatedCompanies(
        page: number,
        pageSize: number,
        search: string,
    ): Observable<{
        data: Company[];
        meta: { total: number };
    }> {
        const params = new HttpParams()
            .set('page', page)
            .set('pageSize', pageSize)
            .set('search', search);
        return this.httpClient
            .get<
                ResponseGeneric<{ data: Company[]; meta: { total: number } }>
            >('companies', { params })
            .pipe(map((res) => res.payload));
    }

    public create(data: CreateCompanyPayload): Observable<Company> {
        return this.httpClient.post<Company>(`companies`, data).pipe(
            map((responseBody) => this.parseResponse(responseBody)),
            tap(() =>
                this.toaster.success('Firma wurde erfolgreich hinzugefügt'),
            ),
        );
    }

    public update(
        companyId: number,
        data: CreateCompanyPayload,
    ): Observable<Company> {
        return this.httpClient
            .put<Company>(`companies/${companyId}`, data)
            .pipe(
                map((responseBody) => this.parseResponse(responseBody)),
                tap(() =>
                    this.toaster.success(
                        'Firma wurde erfolgreich aktualisiert',
                    ),
                ),
            );
    }

    public getEmployees(companyId: number): Observable<User[]> {
        return this.httpClient
            .get<User[]>(`companies/${companyId}/employees`)
            .pipe(
                map((responseBody) => this.userFactory.fromArray(responseBody)),
            );
    }

    public export(exportSettings: ExportSettings): Observable<unknown> {
        return new Observable((observer) => {
            this.httpClient
                .post(
                    'export/company',
                    { exportSettings: exportSettings },
                    {
                        responseType: 'blob',
                        headers: {
                            Accept: 'application/json; charset=utf-8',
                            'Content-Type': 'application/json; charset=utf-8',
                            'X-Skip-Module-Url': '',
                        },
                        observe: 'response',
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error);
                        observer.next(false);
                        observer.complete();
                        return throwError(() => error);
                    }),
                )
                .subscribe((res: HttpResponse<Blob>) => {
                    const content = res.headers.get('content-disposition');
                    if (content) {
                        const filename = content
                            .match(FILENAME_MATCH)[1]
                            .replace(new RegExp(FILENAME_REPLACE2), '');
                        saveAs(res.body, filename);
                    }
                    observer.next(true);
                    observer.complete();
                });
        });
    }

    private parseResponse(response: Company): Company {
        return this.companyFactory.fromObject(response);
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        return throwError(() => error);
    }

    public percentageTasks(company_id: number): Observable<number> {
        const params: { [p: string]: string } = {};
        params['global_view'] = String(this.profileService.global_view);
        return this.httpClient.get<number>(`calculate_tasks/${company_id}`, {
            params,
        });
    }

    public sendInvitationLink(email: string): Observable<{ status: string }> {
        return this.httpClient
            .post<{ status: string }>(`invitation`, { email: email })
            .pipe(map((responseBody) => responseBody));
    }

    public valueExists(valueExists: ValueExists): Observable<boolean> {
        return this.httpClient.post<boolean>(
            `companies/value-exists`,
            valueExists,
        );
    }

    public updateLeasingBudgets(payload: {
        leasing_budgets: { id: number; leasing_budget: number }[];
    }): Observable<ResponseGeneric<number[]>> {
        return this.httpClient
            .put<
                ResponseGeneric<number[]>
            >(`companies/leasing-budgets`, payload)
            .pipe(catchError((error) => this.handleError(error)));
    }

    public getPolicy(slug: string): Observable<CompanyPolicy> {
        const headers = new HttpHeaders().append(SkipModuleUrl, '');

        return this.httpClient
            .get<{
                payload: CompanyPolicy;
            }>(`employee-api/v1/companies/policy?slug=${slug}`, { headers })
            .pipe(map((response) => response.payload));
    }

    public getTransferContractAttachment(
        companyId: number,
    ): Observable<Document[]> {
        return this.httpClient
            .get<{
                payload: Document[];
            }>(`companies/${companyId}/transfer-contract/attachments`)
            .pipe(map((response) => response.payload));
    }

    public addTransferContractAttachment(
        companyId: number,
        formData: FormData,
    ): Observable<Document[]> {
        return this.httpClient
            .post<{
                payload: Document[];
            }>(`companies/${companyId}/transfer-contract/attachments`, formData)
            .pipe(map((response) => response.payload));
    }

    public updatePositiveList(
        companyId: number,
        formData: FormData,
    ): Observable<ResponseGeneric<void>> {
        return this.httpClient
            .post<
                ResponseGeneric<void>
            >(`companies/${companyId}/positive-list`, formData)
            .pipe(
                catchError((error) => {
                    this.toaster.error(
                        error.error.payload?.file?.[0],
                        error.error.message,
                    );
                    return throwError(() => error);
                }),
            );
    }

    public deleteTransferContractAttachment(
        companyId: number,
        attachmentId: number,
    ): Observable<Document[]> {
        return this.httpClient
            .delete<{
                payload: Document[];
            }>(
                `companies/${companyId}/transfer-contract/attachments/${attachmentId}`,
            )
            .pipe(map((response) => response.payload));
    }
}
