import { Injectable } from '@angular/core';
import { Factory } from '@shared/factory';
import { User } from '@shared/models/user';

@Injectable({
    providedIn: 'root',
})
export class UsersFactory extends Factory<User> {
    constructor() {
        super(User);
    }
}
