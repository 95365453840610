import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { PHONE4 } from '@core/constants/regex';

/**
 * @deprecated Use Pattern Validator in FormControl
 */
@Directive({
    selector: '[phone][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PhoneDirective,
            multi: true,
        },
    ],
})
export class PhoneDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const number = control.value;

        if (number && !String(number).match(PHONE4)) {
            return {
                phone: `Telefonnummer sollte 8-13 Ziffern enthalten`,
            };
        }
        return null;
    }
}
