import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    PrivacyPolicyComponent,
    PrivacyPolicyData,
    PrivacyPolicyType,
} from '@layout/privacy-policy/privacy-policy.component';
import { AccessibilityDialogComponent } from '@public/accessibility/accessibility.component';

@Component({
    selector: 'dd-public-footer',
    templateUrl: './public-footer.component.html',
    styleUrls: ['./public-footer.component.scss'],
})
export class PublicFooterComponent {
    public slug = this.activatedRoute.snapshot.params.companySlug;

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
    ) {}

    public openAccessibilityDialog(): void {
        this.dialog.open(AccessibilityDialogComponent, {
            panelClass: 'mat-mdc-dialog-surface--no-padding',
        });
    }

    public openPrivacyPolicyDialog(): void {
        this.dialog.open<PrivacyPolicyComponent, PrivacyPolicyData, void>(
            PrivacyPolicyComponent,
            {
                panelClass: 'mat-mdc-dialog-surface--no-padding',
                data: {
                    type: PrivacyPolicyType.PRIVACY_POLICY,
                    slug: this.slug,
                },
            },
        );
    }
}
