import { Rate, RateType } from './rate';

export enum ServiceRateType {
    INSPECTION = 'inspection',
    FULLSERVICE = 'fullservice',
}

export class ServiceRate extends Rate {
    constructor() {
        super();
        this.rateType = RateType.Service;
    }
}
