/**
 * Abstract class all the models that can be created and fetched through API have to extend.
 * @property endpoint URL to be called to make a simple get. It is useful for making generic shared.
 * @property id unique identifier, usually primary key of a table.
 */
import { Relation } from '@core/relations/relation';

export abstract class Model {
    public static relations: Map<typeof Model, Relation[]> = new Map();
    public endpoint: string;
    protected stored = false;
    public id: number;

    /**
     * Method that returns true if the model is stored to the database (i.e. it is returned by the API) or false if it is
     * created by a Factory and it's still to be created.
     */
    public isStored(): boolean {
        return this.stored;
    }
}
