<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img
                        [src]="logoService.loginLogo"
                        alt=""
                        aria-hidden="true"
                    />
                </div>
                <div class="card">
                    <div class="card-body">
                        <h1
                            class="title"
                            translate
                        >
                            PASSWORD.RESET_PASSWORD_TITLE
                        </h1>

                        <div class="row justify-content-center">
                            <form
                                class="col-md-8"
                                [formGroup]="resetPasswordForm"
                                (ngSubmit)="resetPassword()"
                            >
                                <div class="mb-4">
                                    <mrc-form-item-field
                                        [label]="
                                            'PASSWORD.NEW_PASSWORD' | translate
                                        "
                                        [control]="
                                            resetPasswordForm.controls.email
                                        "
                                        [id]="'newPassword'"
                                    >
                                        <input
                                            id="newPassword"
                                            type="password"
                                            name="newPassword"
                                            class="form-control"
                                            formControlName="password"
                                            [placeholder]="
                                                'PASSWORD.NEW_PASSWORD'
                                                    | translate
                                            "
                                            validateEqual="passwordConfirmation"
                                            reverse="true"
                                        />
                                    </mrc-form-item-field>
                                </div>
                                <div
                                    class="password-validator-container bg-primary mb-4"
                                    *ngIf="
                                        !resetPasswordForm.controls.password
                                            .pristine &&
                                        resetPasswordForm.controls.password
                                            .invalid
                                    "
                                >
                                    <div class="p-3">
                                        <h4 class="title">
                                            {{
                                                'PASSWORD.PASSWORD_REQUIREMENTS_TITLE'
                                                    | translate
                                            }}:
                                        </h4>
                                        <ul
                                            class="mb-0"
                                            [innerHTML]="
                                                'PASSWORD.PASSWORD_REQUIREMENTS'
                                                    | translate
                                            "
                                        ></ul>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <mrc-form-item-field
                                        [label]="
                                            'PASSWORD.NEW_PASSWORD_REPEAT'
                                                | translate
                                        "
                                        [control]="
                                            resetPasswordForm.controls.email
                                        "
                                        [id]="'passwordConfirmation'"
                                    >
                                        <input
                                            id="passwordConfirmation"
                                            type="password"
                                            name="passwordConfirmation"
                                            class="form-control"
                                            formControlName="password_confirmation"
                                            [placeholder]="
                                                'PASSWORD.NEW_PASSWORD_REPEAT'
                                                    | translate
                                            "
                                            validateEqual="password"
                                        />
                                    </mrc-form-item-field>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100"
                                        [disabled]="
                                            resetPasswordForm.disabled ||
                                            passwordChanged
                                        "
                                        translate
                                    >
                                        PASSWORD.RESET_PASSWORD
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
