import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { CityInterface } from '@shared/models/city.interface';
import { catchError, map, Observable, of, shareReplay, throwError } from 'rxjs';

export interface GeoSearchResult {
    postalCode: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class GeoService {
    private getCitiesObservable: Observable<CityInterface[]>;

    constructor(private httpClient: HttpClient) {}

    public getCities(): Observable<CityInterface[]> {
        if (!this.getCitiesObservable) {
            this.getCitiesObservable = this.httpClient
                .get<{ payload: CityInterface[] }>(`geo/cities`)
                .pipe(
                    map(({ payload }) => payload),
                    catchError((error) => {
                        this.clearCache();
                        return throwError(() => error);
                    }),
                    shareReplay(1),
                );
        }

        return this.getCitiesObservable;
    }

    public find(id: number): Observable<CityInterface> {
        return this.getCities().pipe(
            map((cities) => cities.find((city) => city.id === id)),
        );
    }

    public search(search: string): Observable<GeoSearchResult[]> {
        return this.httpClient
            .get<
                ResponseGeneric<GeoSearchResult[]>
            >('geo/search', { params: { search } })
            .pipe(
                map(({ payload }) => payload),
                catchError(() => of([])),
            );
    }

    private clearCache(): void {
        this.getCitiesObservable = null;
    }
}
