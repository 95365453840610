import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
} from '@angular/forms';

/**
 * @deprecated Use Validators.max in FormControl
 */
@Directive({
    selector: '[max][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MaxDirective,
            multi: true,
        },
    ],
})
export class MaxDirective {
    @Input() public max: number;

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value > this.max) {
            return {
                max: `Der maximale Wert ist ${this.max}`,
            };
        }

        return null;
    }
}
