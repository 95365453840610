import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MODULE } from '@core/tokens/module.token';
import { MenuService } from '@layout/sidebar/menu.service';
import {
    Observable,
    ReplaySubject,
    catchError,
    combineLatest,
    map,
    retry,
    tap,
    throwError,
} from 'rxjs';
import { LoginMode } from '../auth/auth-settings.service';
import { Settings } from './settings.interface';

@Injectable()
export class SettingsService implements OnDestroy {
    private settingsSubject$ = new ReplaySubject<Settings>(1);

    public settings$ = this.settingsSubject$.asObservable();

    constructor(
        @Inject(MODULE) private module: LoginMode,
        private http: HttpClient,
        private menuService: MenuService,
    ) {
        this.loadSettings();

        combineLatest([this.settings$, this.menuService.menuSubject]).subscribe(
            ([settings, menu]) => {
                if (
                    menu.menu?.length > 0 &&
                    settings.inspectionEnabled === 0 &&
                    this.module === 'supplier'
                ) {
                    const blockIdx = menu.menu.findIndex((block) =>
                        block.links
                            .map((link) => link.name)
                            .some((name) => name === 'Sichtprüfung'),
                    );

                    if (blockIdx >= 0) {
                        menu.menu.splice(blockIdx, 1);
                        this.menuService.updateMenu(menu.menu);
                    }
                }
            },
        );
    }

    public ngOnDestroy(): void {
        this.settingsSubject$.complete();
    }

    public getCompanySettings(companyId: number): Observable<Settings> {
        return this.http.get<Settings>(
            `settings/extra-company-settings/${companyId}`,
        );
    }

    private loadSettings(): void {
        this.http
            .get<{ payload: Settings }>('settings')
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.error(`Could not load settings: ${error.message}`);
                    return throwError(() => error);
                }),
                map((settings) => settings.payload),
                retry(5),
            )
            .subscribe((settings) => {
                this.settingsSubject$.next(settings);
            });
    }

    public updateSettings(settings: FormData): Observable<Settings> {
        return this.http.post<{ payload: Settings }>('settings', settings).pipe(
            map((settings) => settings.payload),
            tap((settings) => this.settingsSubject$.next(settings)),
        );
    }
}
