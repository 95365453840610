import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export type LoginMode =
    | 'employee'
    | 'company'
    | 'supplier'
    | 'portal'
    | 'system';

@Injectable({ providedIn: 'root' })
export class AuthSettingsService {
    private pathSubject = new ReplaySubject<string>();
    public path$ = this.pathSubject.asObservable();

    private tokenSubject = new ReplaySubject<string>();
    public token$ = this.tokenSubject.asObservable();

    private currentModuleSubject = new ReplaySubject<LoginMode>();
    public currentModule$: Observable<LoginMode> =
        this.currentModuleSubject.asObservable();

    private rolesSubject = new ReplaySubject<string[]>();
    public roles: Observable<string[]> = this.rolesSubject.asObservable();

    private permissionsSubject = new ReplaySubject<string[]>(1);
    public permissions$: Observable<string[]> =
        this.permissionsSubject.asObservable();

    public updatePath(value: string): void {
        this.pathSubject.next(value);
    }

    public updateToken(value: string): void {
        this.tokenSubject.next(value);
    }

    public updateCurrentModule(value: LoginMode): void {
        this.currentModuleSubject.next(value);
    }

    // type "string" is not correct here. role should be typed at some point
    public updateRoles(value: string[]): void {
        this.rolesSubject.next(value);
    }

    public updatePermissions(permissions: { name: string }[]): void {
        if (permissions) {
            this.permissionsSubject.next(permissions.map((p) => p.name));
        } else {
            this.permissionsSubject.next([]);
        }
    }
}
