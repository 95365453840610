import { Directive, forwardRef } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { PASSWORD_MATCH } from '@core/constants/regex';

/**
 * @deprecated Use CustomPasswordValidators in FormControl
 */
@Directive({
    selector: '[password][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordDirective),
            multi: true,
        },
    ],
})
export class PasswordDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const password = control.value;
        let email = control.root.get('email');
        let name = control.root.get('first_name');
        let surname = control.root.get('last_name');
        email = email ? email.value : null;
        name = name ? name.value : null;
        surname = surname ? surname.value : null;

        if (password && !password.match(PASSWORD_MATCH)) {
            return {
                password: `Ungültiges Passwort`,
            };
        }
        if (
            password &&
            (password.includes(email) ||
                password.includes(name) ||
                password.includes(surname))
        ) {
            return {
                password: `Ungültiges Passwort`,
            };
        }
        return null;
    }
}
