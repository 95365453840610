import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'dd-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent {
    @Input() public files: File[] = [];
    @Output() public handleFileDelete = new EventEmitter<number>();
}
