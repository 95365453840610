import { Model } from './model';
import { PermissionModel } from './permission.model';
import { Supplier } from './supplier';

enum ROLES {
    SYSTEM = 1,
    PORTAL = 2,
    COMPANY = 3,
}

export class UserRoleInfo extends Model {
    public companyEnabled: boolean;
    public company: any;
    public portal: any;
    public supplierEnabled: boolean;
    public supplier: Supplier;
    public portalEnabled: boolean;
    public portalId: number;
    public isPortalAdmin: boolean;
    public hasEditPermission: boolean;
    public permissions: PermissionModel[];
    public isSystemAdmin: boolean;
    public isSubSupplier: boolean;
    public parentSupplier: Supplier;

    constructor() {
        super();
        this.companyEnabled = false;
        this.supplierEnabled = false;
        this.isPortalAdmin = false;
        this.hasEditPermission = false;
        this.portalEnabled = false;
        this.isSystemAdmin = false;
        this.company = new Object();
        this.supplier = new Supplier();
        this.isSubSupplier = false;
        this.parentSupplier = null;
    }

    public set role(rol: number) {
        if (rol === ROLES.SYSTEM) {
            this.isSystemAdmin = true;
            this.isPortalAdmin = false;
            this.companyEnabled = false;
        } else if (rol === ROLES.PORTAL) {
            this.isPortalAdmin = true;
            this.isSystemAdmin = false;
            this.companyEnabled = false;
        } else {
            this.companyEnabled = true;
            this.isSystemAdmin = false;
            this.isPortalAdmin = false;
        }
    }

    public setRelations(): void {
        if (!this.company) {
            this.company = new Object();
        }
        if (!this.supplier) {
            this.supplier = new Supplier();
        }
    }

    public hasCompany(): boolean {
        return this.company && this.company.id > 0;
    }

    public hasSupplier(): boolean {
        return this.supplier && this.supplier.id > 0;
    }

    public hasPortal(): boolean {
        return this.portalId > 0;
    }
}
