import { Pipe, PipeTransform } from '@angular/core';
import { roundTo } from '@core/helpers/math';

@Pipe({
    name: 'fileSizeInMb'
})
export class FileSizeInMbPipe implements PipeTransform {
    public transform(bytes: number): string {
        if (!bytes) {
            return '-';
        }
        return roundTo(bytes / Math.pow(1024, 2), 2).toString();
    }
}