import { Mixin } from '@common/mixin';
import { startOfToday } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { Model } from './model';
import { ProductCategory } from './product-category';
import { ServiceRateType } from './service-rate';

export enum AmountType {
    Fixed = 'fixed',
    Percentage = 'percentage',
}

export enum RateType {
    Insurance,
    Service,
}

export class Rate extends Mixin<Model>(Model) {
    public amount: number;
    public amountType: AmountType;
    public minimum: number;
    public name: string;
    public id: number;
    public default: boolean;
    public productCategory: ProductCategory;
    public rateType: RateType;
    public type: ServiceRateType;
    public budget: number;
    public activeAt = startOfToday();
    public inactiveAt = null;

    constructor() {
        super();
        this.id = uuidv4();
    }

    public getText(): string {
        if (this.amount) {
            if (this.amountType === AmountType.Fixed) {
                return `${Number(this.amount)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')} €`;
            } else {
                return `${this.amount.toString().replace('.', ',')} %`;
            }
        }
        return ' - ';
    }
}
