import { CoverageType } from '@app/individual-settings/enums/coverage-type';
import { Mixin } from '../../common/mixin';
import { HasMany } from '../../core/relations/has-many';
import { DiscountMode } from '../models/supplier.interface';
import { CityInterface } from './city.interface';
import { HasLeasingSettings } from './has-leasing-settings';
import { LeasingCondition } from './leasing-condition';
import { Model } from './model';
import { Status } from './status';

export enum PortalStatus {
    Active = 3,
    Inactive = 4,
}

interface PortalMixin extends Model, HasLeasingSettings {}

@HasMany(LeasingCondition, 'leasingConditions')
export class Portal extends Mixin<PortalMixin>(Model, HasLeasingSettings) {
    public endpoint = 'portals';
    public id: number;
    public code: string;
    public partnerId: number;
    public bankId: number;
    public logo: string | Blob;
    public color: string;
    public name: string;
    public domain: string;
    public baseDomain: string;
    public subdomain: string;
    public status: Status;
    public leasingablePdf: string | Blob;
    public adminEmail: string;
    public adminFirstName: string;
    public adminLastName: string;
    public companyAddress: string;
    public companyCity: CityInterface;
    public companyName: string;
    public companyVat: string;
    public companyPhoneNumber: string;
    public policyPdf: string | Blob;
    public policy: string;
    public autoresponderText: string;
    public automaticCreditNote: boolean;
    public inspectionAutomaticCreditNote: boolean;
    public allowEmployeeOfferCreation: boolean;
    public imprintPdf: string | Blob;
    public imprint: string;
    public leasingRateSubsidy = false;
    public leasingRateSubsidyType = CoverageType.PERCENTAGE;
    public leasingRateSubsidyAmount = 100;
    public insuranceRateSubsidy = false;
    public insuranceRateSubsidyType = CoverageType.PERCENTAGE;
    public insuranceRateSubsidyAmount = 100;
    public serviceRateSubsidy = false;
    public serviceRateSubsidyType = CoverageType.PERCENTAGE;
    public serviceRateSubsidyAmount = 100;
    public creditNoteDiscount: number;
    public discountMode: DiscountMode;
    public inspectionEnabled: boolean;
    public productCategoryIds: number[];
    public enableGovecs: boolean;
    public enableDelivery: boolean;
    public enableSubSuppliers: boolean;
    public enableGovecsCalculator: boolean;

    public isActive(): boolean {
        return this.status.id === PortalStatus.Active;
    }
}
