import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Factory } from '@shared/factory';
import { catchError, filter, map, Observable, throwError } from 'rxjs';
import { LoginService } from '../../login.service';
import { LoginVerification } from '../login-verification';

@Injectable({
    providedIn: 'root',
})
export class ExternalLoginResolver {
    constructor(
        private loginService: LoginService,
        private router: Router
    ) {}

    public resolve(
        route: ActivatedRouteSnapshot
    ):
        | Observable<LoginVerification>
        | Promise<LoginVerification>
        | LoginVerification {
        const challenge = route.queryParamMap.get('login_challenge');
        return this.loginService.verifyLoginChallenge(challenge).pipe(
            map((response) =>
                new Factory(LoginVerification).fromObject(response)
            ),
            filter((verification) => {
                if (verification.skip && verification.redirectTo) {
                    window.location.replace(verification.redirectTo);
                    return false;
                }
                return true;
            }),
            catchError((error) => {
                this.router.navigate(['error', error.error.code], {
                    queryParams: {
                        message:
                            'Bei der Bestätigung Ihres Logins ist ein Fehler aufgetreten',
                    },
                });
                return throwError(() => error);
            })
        );
    }
}
