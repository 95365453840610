<div
    class="form-control-holder pdf-uploader"
    *ngIf="!fileUrl || hasFileToUpload()"
>
    <div
        class="upload-block"
        [hidden]="fileUrl || fileData"
        *ngIf="!disabled"
    >
        <input
            #fileField
            type="file"
            name="logo"
            accept="application/pdf"
            (change)="load($event.target.files)"
        />
        <i
            aria-hidden="true"
            class="fal fa-cloud-upload-alt"
        ></i
        ><br />
        <div class="pdf-link">
            {{ 'PDF_UPLOAD.CLICK_HERE_TO_UPLOAD_A_FILE' | translate }}
        </div>
    </div>

    <div
        class="uploaded-block"
        *ngIf="(fileUrl || fileData) && !document"
    >
        <button
            type="button"
            (click)="remove()"
            class="btn btn-light close"
            *ngIf="!disabled"
            [attr.aria-label]="'PDF_UPLOAD.DELETE' | translate"
        >
            <i
                aria-hidden="true"
                class="fal fa-times"
            ></i>
        </button>
        <div class="upload-block">
            <i
                aria-hidden="true"
                class="fal fa-file-pdf"
            ></i
            ><br />
            <div
                class="pdf-link"
                *ngIf="hasFileToUpload()"
            >
                {{ file.size | fileSizeInMb }} MB
            </div>
            <a
                *ngIf="fileData && !fileUrl"
                [href]="fileData"
                target="_blank"
                >{{ 'PDF_UPLOAD.FILE_UPLOADED' | translate }}</a
            >
            <a
                *ngIf="fileUrl"
                [href]="safeFileUrl"
                target="_blank"
                >{{ 'PDF_UPLOAD.FILE_UPLOADED' | translate }}</a
            >
        </div>
    </div>
    <div
        class="uploaded-block"
        *ngIf="document"
    >
        <button
            type="button"
            (click)="remove()"
            class="close"
            *ngIf="!disabled"
            [attr.aria-label]="'PDF_UPLOAD.DELETE' | translate"
        >
            <i
                aria-hidden="true"
                class="fal fa-times"
            ></i>
        </button>
        <div class="upload-block">
            <i
                aria-hidden="true"
                class="fal fa-file-pdf"
            ></i
            ><br />
            <a
                [href]="getPublicPath()"
                target="_blank"
                rel="noopener"
            >
                <div class="pdf-link">
                    {{ document.filename }}
                </div>
                <div class="pdf-link">
                    {{ document.size | fileSizeInMb }} MB
                </div>
                {{ 'PDF_UPLOAD.FILE_UPLOADED' | translate }}</a
            >
        </div>
    </div>
</div>

<div
    class="form-control-holder pdf-uploader"
    *ngIf="fileUrl && !hasFileToUpload()"
>
    <div class="uploaded-block">
        <button
            type="button"
            (click)="remove()"
            class="close"
            [attr.aria-label]="'PDF_UPLOAD.DELETE' | translate"
        >
            <i
                aria-hidden="true"
                class="fal fa-times"
            ></i>
        </button>
        <button
            type="button"
            class="btn upload-block"
            (click)="emitDownload($event)"
        >
            <i
                aria-hidden="true"
                class="fal fa-file-pdf"
            ></i
            ><br />
            <a
                *ngIf="fileData && !fileUrl"
                [href]="fileData"
                target="_blank"
                >{{ 'PDF_UPLOAD.FILE_UPLOADED' | translate }}</a
            >
            <a
                *ngIf="fileUrl && !privateFile"
                [href]="getPublicPath()"
                target="_blank"
                rel="noopener"
                >{{ 'PDF_UPLOAD.FILE_UPLOADED' | translate }}</a
            >
        </button>
    </div>
</div>
