import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserRole } from '@app/users/interfaces/user-role.interface';
import { LoginAsResponse } from '@app/users/users.service';
import { MODULE } from '@core/tokens/module.token';
import { LoginMode } from '../auth/auth-settings.service';

@Injectable({ providedIn: 'root' })
export class RedirectService {
    constructor(@Inject(MODULE) private module: LoginMode) {}

    public buildRedirectUrl(
        redirectTo: string,
        tokenKey: string,
        {
            portalDomain,
            token: { access_token, expires_in },
            expiryTimestamp,
            user,
        }: LoginAsResponse,
        global_view: boolean,
        role?: UserRole,
    ): string {
        let port = location.port ? ':' + location.port : '';
        if (port !== '' && this.module === 'system') {
            port = ':4200';
        }
        const baseUrl = `${location.protocol}//${portalDomain}${port}/redirect?`;
        let params = new HttpParams();
        const companySlug = role?.slug ? role.slug : user.company?.slug;
        if (companySlug) {
            if (redirectTo.includes('firma')) {
                redirectTo = `${redirectTo}/${companySlug}/admin/home`;
            } else if (redirectTo.includes('employee')) {
                redirectTo = `firma/${companySlug}${redirectTo}/home`;
            }
        }
        params = params.set('redirect_to', redirectTo);
        params = params.set('token_key', tokenKey);
        params = params.set('access_token', access_token);
        params = params.set('expire', expires_in);
        params = params.set('expiry_timestamp', expiryTimestamp);
        params = params.set('global_view', global_view.toString());

        switch (tokenKey) {
            case 'supplier_access_token':
                params = params.set('roleable_name', 'Supplier Admin');
                break;

            case 'company_access_token':
                params = params.set('roleable_name', 'Company Admin');
                break;

            case 'employee_access_token':
                params = params.set('roleable_name', 'Employee');
                break;

            case 'access_token':
                params = params.set('roleable_name', 'Portal Admin');
                break;
        }

        const roleableId =
            role?.roleable_id >= 0
                ? role.roleable_id
                : user.active_role.roleable_id || user.roles[0].roleable_id;
        params = params.set('roleable_id', roleableId);

        return `${baseUrl}${params.toString()}`;
    }
}
