<button
    type="button"
    (click)="navigateToLogin()"
    class="d-flex align-items-center justify-content-center ms-login-button"
    data-testid="ms-button"
>
    <span class="ms-icon"></span>
    <span
        class="ms-button-label"
        data-testid="ms-button-label"
        >{{
            openIdConfig.buttonLabel ||
                ('LOGIN.MICROSOFT_LOGIN_LABEL' | translate)
        }}</span
    >
</button>
