<ng-template #template>
    <div class="modal-close">
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="accept()"
        ></button>
    </div>
    <div
        class="modal-header"
        *ngIf="title"
    >
        <h2 class="modal-title">{{ title }}</h2>
    </div>
    <div
        class="modal-body d-flex flex-column justify-content-center align-items-center text-center"
    >
        <ng-content></ng-content>
    </div>
    <div
        class="modal-footer d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center"
    >
        <button
            type="button"
            *ngIf="!closeButton"
            (click)="accept()"
            class="btn btn-primary"
        >
            {{ buttonText ? buttonText : 'OK' }}
        </button>
    </div>
</ng-template>
