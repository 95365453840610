import {
    Component,
    DestroyRef,
    OnInit,
    computed,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '@companies/companies.service';
import { ForbiddenError } from '@core/exceptions/error';
import { AuthSettingsService } from '@core/services/auth/auth-settings.service';
import { AuthenticationService } from '@core/services/auth/auth.service';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { LogoService } from '@core/services/logo/logo.service';
import { TypedFormGroup } from '@core/types/form.type';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import {
    PrivacyPolicyComponent,
    PrivacyPolicyData,
    PrivacyPolicyType,
} from '@layout/privacy-policy/privacy-policy.component';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityDialogComponent } from '@public/accessibility/accessibility.component';
import { Login } from '@public/interfaces/login.interface';
import { Company } from '@shared/models/company';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

@Component({
    selector: 'mrc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public isSso: boolean;

    public loginForm = new FormGroup<TypedFormGroup<Login>>({
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(180),
            CustomEmailValidator,
        ]),
        password: new FormControl('', [
            Validators.required,
            Validators.maxLength(60),
        ]),
    });

    public company = signal<Company>(undefined);
    public companyName = computed(() => this.company()?.name ?? '');
    public showAzureLogin = computed(
        () =>
            !!(
                this.company()?.openIdConfiguration?.type === 'azure' &&
                this.company()?.openIdConfiguration?.isActive
            ),
    );

    constructor(
        public logoService: LogoService,
        private authService: AuthenticationService,
        private toastrService: ToastrService,
        private authSettings: AuthSettingsService,
        private activatedRoute: ActivatedRoute,
        private companiesService: CompaniesService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private tokenStorageService: TokenStorageService,
    ) {
        this.isSso =
            this.activatedRoute.snapshot.firstChild?.data?.type === 'sso';
        this.tokenStorageService.clearAll();
    }

    public ngOnInit(): void {
        if (this.activatedRoute.snapshot.params.companySlug) {
            this.companiesService
                .checkSlug(this.activatedRoute.snapshot.params.companySlug)
                .subscribe((company) => {
                    this.company.set(company);
                });
        }
    }

    public login(): void {
        this.loginForm.updateValueAndValidity();
        if (this.loginForm.invalid) {
            return;
        }

        this.loginForm.disable();
        this.authService
            .login(this.loginForm.getRawValue())
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                catchError((error) => {
                    this.loginForm.enable();
                    if (error instanceof ForbiddenError) {
                        this.toastrService.error(
                            this.translateService.instant(error.message),
                        );
                    } else {
                        this.toastrService.error(error.error.message);
                    }
                    return throwError(() => error);
                }),
            )
            .subscribe((response) => {
                const { roles, permissions } = response.payload.user;
                this.authSettings.updateRoles(roles);
                this.authSettings.updatePermissions(permissions);
                this.loginForm.enable();
            });
    }

    public isWeihnachten(): boolean {
        const today = new Date();
        return today.getMonth() + 1 === 12;
    }

    public openAccessibilityDialog(): void {
        this.dialog.open(AccessibilityDialogComponent, {
            panelClass: 'mat-mdc-dialog-surface--no-padding',
        });
    }

    public openPrivacyPolicyDialog(): void {
        this.dialog.open<PrivacyPolicyComponent, PrivacyPolicyData, void>(
            PrivacyPolicyComponent,
            {
                panelClass: 'mat-mdc-dialog-surface--no-padding',
                data: {
                    type: PrivacyPolicyType.PRIVACY_POLICY,
                    slug: this.activatedRoute.snapshot.params.companySlug,
                },
            },
        );
    }
}
