import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { AdditionalFieldService } from '@app/additional-fields/additional-field.service';
import { catchError, map, Observable, of, switchMap, timer } from 'rxjs';
import { ValueExists } from '../interfaces/value-exists';

const DUPLICATE_ATTRIBUTE_VALIDATOR_DEBOUNCE_TIME_IN_MS = 300;

export function duplicateAttributeValidator(
    addtionalFieldService: AdditionalFieldService,
    key: string,
    slug: string,
    userId?: number,
    debounce?: number,
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return timer(
            debounce ?? DUPLICATE_ATTRIBUTE_VALIDATOR_DEBOUNCE_TIME_IN_MS,
        ).pipe(
            switchMap(() => {
                const input = control.value;

                if (key === '' || input === '') {
                    return of(null);
                } else {
                    return addtionalFieldService
                        .valueExists(key, {
                            slug: slug,
                            value: input,
                            userId: userId,
                        } as ValueExists)
                        .pipe(
                            map((result) =>
                                result
                                    ? {
                                          'ADDITIONAL_FIELDS.USER.DUPLICATE_ERROR':
                                              true,
                                      }
                                    : null,
                            ),
                            catchError(() => of(null)),
                        );
                }
            }),
        );
    };
}
