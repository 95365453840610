<div class="layout-unauthorized" *ngIf="settings$ | async as settings">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img [src]="settings.logo" *ngIf="settings.logo" alt="" />
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <p [innerHTML]="settings.maintenance_text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
