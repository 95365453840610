import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * @deprecated Use Validator in FormControl
 */
@Directive({
    selector:
        '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EqualValidator),
            multi: true,
        },
    ],
})
export class EqualValidator implements Validator {
    constructor(
        @Attribute('validateEqual') public validateEqual: string,
        @Attribute('reverse') public reverse: string,
    ) {}

    private get isReverse(): boolean {
        if (!this.reverse) {
            return false;
        }
        return this.reverse === 'true';
    }

    public validate(c: AbstractControl): { [key: string]: any } {
        // self value
        const value = c.value;
        // control value
        const valueOther = c.parent.get(this.validateEqual);

        // value not equal
        if (valueOther && value !== valueOther.value && !this.isReverse) {
            return {
                validateEqual: `Passwörter stimmen nicht überein`,
            };
        }

        if (valueOther && value === valueOther.value && this.isReverse) {
            if (valueOther.errors) {
                delete valueOther.errors['validateEqual'];
            }
            if (valueOther.errors && !Object.keys(valueOther.errors).length) {
                valueOther.setErrors(null);
            }
        }

        if (valueOther && value !== valueOther.value && this.isReverse) {
            valueOther.setErrors({
                validateEqual: `Passwörter stimmen nicht überein`,
            });
        }

        return null;
    }
}
