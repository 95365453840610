import { ValidatorFn, Validators } from '@angular/forms';
import { PASSWORD } from '@core/constants/regex';
import { customPatternValidator } from '@core/validators/custom-pattern.validator';

export const CustomPasswordPatternValidator: ValidatorFn =
    customPatternValidator({
        validationKey: 'passwordPattern',
        pattern: PASSWORD,
    });

export const CustomPasswordValidators: ValidatorFn[] = [
    Validators.required,
    CustomPasswordPatternValidator,
];
