import { LeasingSettingType } from '@leasing-settings/types/leasing-setting.type';
import {
    isFuture,
    isPast,
    isTomorrow,
    startOfToday,
    startOfTomorrow,
} from 'date-fns';

export class LeasingSettingHelper {
    public static isActive(leasingSetting: LeasingSettingType): boolean {
        const activeDate = new Date(leasingSetting.activeAt);
        const inactiveDate = new Date(leasingSetting.inactiveAt);
        return (
            isPast(activeDate) &&
            (!leasingSetting.inactiveAt || isFuture(inactiveDate))
        );
    }

    public static isInactive(leasingSetting: LeasingSettingType): boolean {
        const activeDate = new Date(leasingSetting.activeAt);
        const inactiveDate = new Date(leasingSetting.inactiveAt);
        return (
            isPast(activeDate) &&
            !!leasingSetting.inactiveAt &&
            isPast(inactiveDate)
        );
    }

    public static isDeactivatedTomorrow(
        leasingSetting: LeasingSettingType,
    ): boolean {
        const activeDate = new Date(leasingSetting.activeAt);
        const inactiveDate = new Date(leasingSetting.inactiveAt);
        return (
            isPast(activeDate) &&
            !!leasingSetting.inactiveAt &&
            isTomorrow(inactiveDate)
        );
    }

    public static isFuture(leasingSetting: LeasingSettingType): boolean {
        const activeDate = new Date(leasingSetting.activeAt);
        return isFuture(activeDate) && leasingSetting.inactiveAt === null;
    }

    public static deactivateToday(leasingSetting: LeasingSettingType): void {
        leasingSetting.activeAt = startOfToday();
        leasingSetting.inactiveAt = startOfToday();
    }

    public static deactivateTomorrow(leasingSetting: LeasingSettingType): void {
        leasingSetting.inactiveAt = startOfTomorrow();
    }

    public static activateTomorrow(leasingSetting: LeasingSettingType): void {
        leasingSetting.activeAt = startOfTomorrow();
        leasingSetting.inactiveAt = null;
    }
}
