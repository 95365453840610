<div class="file-upload">
    <input
        [id]="formFieldId"
        type="file"
        name="file"
        [accept]="config.acceptedFileTypes | joinFileType"
        [multiple]="config.multiple"
        (change)="onFileInput($event.target.files)"
        (click)="$event.target.value = null"
        data-testid="file-upload-input"
    />
    <i
        aria-hidden="true"
        class="fal fa-cloud-upload-alt"
    ></i>
    <label [for]="formFieldId">
        <p
            class="mt-3"
            translate
        >
            {{ config.text }}
        </p>
        <small
            translate
            [translateParams]="{ fileSize: config.maxFileSizeInMb }"
        >
            GENERIC.FILE_UPLOAD.MAX_FILE_SIZE
        </small>
    </label>
    <div class="focus-ring"></div>
</div>
