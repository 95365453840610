import {
    Directive,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * @deprecated Use Validator in FormControl
 */
@Directive({
    selector:
        '[compareNumber][formControlName],[compareNumber][formControl],[compareNumber][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CompareNumberDirective),
            multi: true,
        },
    ],
})
export class CompareNumberDirective implements Validator, OnChanges {
    private _onChange: () => void;

    @Input() public compareNumber: string | number;

    public validate(c: AbstractControl): { [key: string]: any } {
        const v = c.value;

        if (this.compareNumber && +v > +this.compareNumber) {
            return {
                compareNumber: `Wert sollte nicht mehr als Normaler Preis sein`,
            };
        }
        return null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('compareNumber' in changes && this._onChange) {
            setTimeout(() => this._onChange());
        }
    }
}
