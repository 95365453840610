<div class="modal-close">
    <button
        type="button"
        class="btn-close"
        [aria-label]="'GENERIC.ACTIONS.CLOSE' | translate"
        mat-dialog-close
    ></button>
</div>
<h2
    mat-dialog-title
    translate
>
    ACCESSIBILITY_DECLARATION.TITLE
</h2>
<div mat-dialog-content>
    <p [innerHTML]="'ACCESSIBILITY_DECLARATION.PARAGRAPH_1' | translate"></p>

    <h3 translate>ACCESSIBILITY_DECLARATION.STATE</h3>

    <p
        [innerHTML]="'ACCESSIBILITY_DECLARATION.PARAGRAPH_2' | translate"
        class="mb-0"
    ></p>

    <ul>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_1</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_2</li>
    </ul>

    <p
        [innerHTML]="('ACCESSIBILITY_DECLARATION.PARAGRAPH_3' | translate) + ':'"
        class="mb-0"
    ></p>
    <ol>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_3</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_4</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_5</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_6</li>
    </ol>

    <h3 translate>ACCESSIBILITY_DECLARATION.ACCESSIBLE</h3>
    <p
        [innerHTML]="('ACCESSIBILITY_DECLARATION.PARAGRAPH_4' | translate) + ':'"
        class="mb-0"
    ></p>

    <ul>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_7</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_8</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_9</li>
        <li translate>ACCESSIBILITY_DECLARATION.LIST_10</li>
    </ul>

    <h3 translate>ACCESSIBILITY_DECLARATION.CREATION_DATE</h3>

    <p translate>ACCESSIBILITY_DECLARATION.PARAGRAPH_5</p>

    <h3 translate>ACCESSIBILITY_DECLARATION.REPORT_BARRIER</h3>
    <p [innerHTML]="'ACCESSIBILITY_DECLARATION.PARAGRAPH_6' | translate"></p>

    <h3 translate>ACCESSIBILITY_DECLARATION.ARBITRATION_PROCEEDINGS</h3>
    <p [innerHTML]="'ACCESSIBILITY_DECLARATION.PARAGRAPH_7' | translate"></p>
</div>
