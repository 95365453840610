import { PercentPipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'customPercent',
})
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class CustomPercentPipe extends PercentPipe {
    public transform(value: number, digitsInfo?: string, locale?: string): any {
        const dividedValue = value / 100;
        const defaultDigitsInfo = digitsInfo ?? '1.0-3';
        return super.transform(dividedValue, defaultDigitsInfo, locale);
    }
}
