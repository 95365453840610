import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: 'ng-select',
})
export class NgSelectComboboxDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    public ngAfterViewInit(): void {
        const baseElement = this.el.nativeElement;
        baseElement
            .querySelector('.ng-input')
            .removeAttribute('role', 'combobox');
        baseElement.querySelector('input').setAttribute('role', 'combobox');
    }
}
