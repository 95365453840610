import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OpenIdConfiguration } from '@shared/models/open-id-configuration';

@Component({
    selector: 'dd-microsoft-login-button',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './microsoft-login-button.component.html',
    styleUrls: ['./microsoft-login-button.component.scss'],
})
export class MicrosoftLoginButtonComponent {
    @Input({ required: true }) public openIdConfig: OpenIdConfiguration;

    public navigateToLogin(): void {
        if (this.openIdConfig.login) {
            window.location.href = this.openIdConfig.login;
        }
    }
}
