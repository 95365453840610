import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRole } from '@app/users/interfaces/user-role.interface';

export interface DialogData {
    roles: UserRole[];
}

export interface CloseDialogData {
    role: UserRole;
}

@Component({
    selector: 'dd-login-select-role-dialog',
    templateUrl: './login-select-role-dialog.component.html',
})
export class LoginSelectRoleDialogComponent {
    public selectedRole: UserRole;

    constructor(
        public dialogRef: MatDialogRef<
            LoginSelectRoleDialogComponent,
            CloseDialogData
        >,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        if (data.roles) {
            data.roles.forEach((role: UserRole & { selectLabel: string }) => {
                role.selectLabel = role.roleable_name + ' - ' + role.label;
            });
            this.selectedRole = data.roles[0];
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        this.dialogRef.close({ role: this.selectedRole });
    }
}
