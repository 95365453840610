import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
    selector: 'mrc-validation-errors',
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent {
    @Input() public id: string;
    @Input() private invalid: boolean;
    @Input() public errors: ValidationErrors;
    @Input() private dirty: boolean;

    public get isVisible(): boolean {
        return this.dirty && this.invalid;
    }
}
