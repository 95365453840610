import {
    AbstractControl,
    FormArray,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export function uniqueOptionValidator(formArray: FormArray): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const allOptionsLowerCase = (formArray.value as string[]).map((value) =>
            value.toLowerCase()
        );

        return allOptionsLowerCase.includes(
            (control.value as string).toLowerCase()
        )
            ? { uniqueOption: true }
            : null;
    };
}
