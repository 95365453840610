import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AdditionalField } from './interfaces/additional-field';
import { ValueExists } from './interfaces/value-exists';

@Injectable()
export class AdditionalFieldService {
    constructor(
        private http: HttpClient,
        private toastrService: ToastrService
    ) {}

    public create(field: AdditionalField): Observable<AdditionalField> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .post<AdditionalField>('additional-fields', field, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde hinzugefügt'
                    )
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Erstellen des zusätzlichen Registrierungsfelds ist etwas schief gelaufen'
                    );
                    return throwError(() => error);
                })
            );
    }

    public update(field: AdditionalField): Observable<AdditionalField> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .post<AdditionalField>(`additional-fields/${field.id}`, field, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde aktualisiert'
                    )
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Aktualisieren des zusätzlichen Registrierungsfelds ist etwas schief gelaufen'
                    );
                    return throwError(() => error);
                })
            );
    }

    public valueExists(
        key: string,
        valueExists: ValueExists
    ): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http.post<boolean>(
            `additional-fields/value-exists/${key}`,
            valueExists,
            { headers: headers }
        );
    }

    public conditionAlreadySet(
        key: string,
        userGroupId: number,
        value: string
    ): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http.post<boolean>(
            `user-groups/additional-fields/check-duplicate`,
            { key: key, userGroupId: userGroupId, value: value },
            { headers: headers }
        );
    }

    public delete(field: AdditionalField): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .delete<boolean>(`additional-fields/${field.id}`, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde gelöscht'
                    )
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Löschen des zusätzlichen Registrierungsfelds ist etwas schief gelaufen'
                    );
                    return throwError(() => error);
                })
            );
    }
}
