<div class="row">
    <div class="col-4 pe-0">
        <div class="row g-0">
            <mrc-status
                class="col-2 p-0"
                [status]="offerStatus"
                [statusIcons]="statusIcons"
                [btnBorder]="btnBorder"
                [showLabel]="showLabel"
                aria-hidden="true"
            ></mrc-status>
            <div class="col-6 px-2">
                {{ 'GENERIC.LABELS.OFFER' | translate }} {{ offerStatus.label }}
            </div>
            <div class="col-auto">
                <dd-info-tooltip
                    class="info"
                    [text]="offerStatus.tooltip_content"
                ></dd-info-tooltip>
            </div>
            <div class="line col p-0 d-none d-md-block"></div>
        </div>
    </div>
    <div class="col-4 pe-0">
        <div class="row g-0">
            <mrc-status
                class="col-2 p-0"
                [status]="orderStatus"
                [statusIcons]="statusIcons"
                [btnBorder]="btnBorder"
                [showLabel]="showLabel"
                aria-hidden="true"
            ></mrc-status>
            <div class="col-6 px-2">
                {{ 'GENERIC.LABELS.ORDER' | translate }} {{ orderStatus.label }}
            </div>
            <div class="col-auto">
                <dd-info-tooltip
                    class="info"
                    [text]="orderStatus.tooltip_content"
                ></dd-info-tooltip>
            </div>
            <div class="line col p-0 d-none d-md-block"></div>
        </div>
    </div>
    <div class="col-4">
        <div class="row g-0">
            <mrc-status
                class="col-2 p-0"
                [status]="contractStatus"
                [statusIcons]="statusIcons"
                [btnBorder]="btnBorder"
                [showLabel]="showLabel"
                aria-hidden="true"
            ></mrc-status>
            <div class="col-8 px-2">
                {{ 'GENERIC.LABELS.CONTRACT' | translate }}
                {{ contractStatus.label }}
            </div>
            <div class="col-auto">
                <dd-info-tooltip
                    class="info"
                    [text]="contractStatus.tooltip_content"
                ></dd-info-tooltip>
            </div>
        </div>
    </div>
</div>
