import { Pipe, PipeTransform } from '@angular/core';
import { FileType } from '../enums/file-type';

@Pipe({
    name: 'joinFileType',
})
export class JoinFileTypePipe implements PipeTransform {
    public transform(value: FileType[]): string {
        return value.join(',');
    }
}
