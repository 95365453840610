import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decodeUri',
})
export class DecodeUriFilePipe implements PipeTransform {
    public transform(value: string): string {
        return decodeURIComponent(value);
    }
}
