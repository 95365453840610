import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { AuthenticationService } from '@core/services/auth/auth.service';
import { MODULE } from '@core/tokens/module.token';
import { environment } from '@environments/environment';
import { Document } from '@models/document';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'mrc-pdf-uploader',
    templateUrl: './pdf-uploader.component.html',
    styleUrls: ['./pdf-uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PdfUploaderComponent),
            multi: true,
        },
    ],
})
export class PdfUploaderComponent implements ControlValueAccessor {
    @Input() public fileUrl: string;
    @Input() public maxMb = 4;
    @Input() public privateFile = false;
    @Input() public disabled = false;
    @Input() public document: Document;
    @ViewChild('fileField') public fileElement: ElementRef;

    @Output() public download = new EventEmitter<void>();
    private _safeFileUrl = null;
    private accessToken: string;

    public get safeFileUrl(): SafeResourceUrl {
        if (!this._safeFileUrl) {
            this._safeFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.fileUrl,
            );
        }
        return this._safeFileUrl;
    }

    public fileData: SafeResourceUrl;
    @Output() public fileDataOut: EventEmitter<SafeResourceUrl> =
        new EventEmitter<SafeResourceUrl>();
    @Output() public pathOut: EventEmitter<SafeResourceUrl> =
        new EventEmitter<SafeResourceUrl>();
    public path: SafeResourceUrl;
    public file: Blob;
    public publicUrl: SafeResourceUrl;

    private onChange: (value) => void = () => {
        return;
    };
    private onTouched: () => void = () => {
        return;
    };

    constructor(
        private toastrService: ToastrService,
        private sanitizer: DomSanitizer,
        private authService: AuthenticationService,
        private translateService: TranslateService,
        @Inject(MODULE) private module: LoginMode,
    ) {
        this.authService.getAccessToken().subscribe((accessToken) => {
            this.accessToken = accessToken;
        });
        this.path = environment.apiUrl + '/storage/';
    }

    public load(file: FileList): void {
        this.onTouched();
        if (file.item(0).type !== 'application/pdf') {
            this.toastrService.error(
                this.translateService.instant('ERROR.CHOOSE_VALID_PDF'),
            );
            return;
        }
        if (this.fileExceedsMaximum(file.item(0).size)) {
            this.toastrService.error(
                this.translateService.instant('ERROR.CHOOSE_FILE_MAX_SIZE', {
                    maxMb: this.maxMb,
                }),
            );
            return;
        }
        this.file = file.item(0);

        const reader = new FileReader();
        reader.onload = (event: any): void => {
            this._safeFileUrl = null;
            this.fileData = this.sanitizer.bypassSecurityTrustResourceUrl(
                event.target.result,
            );
            this.pathOut.emit(event.target.result);
            this.onChange(event.target.result);
        };
        reader.readAsDataURL(this.file);
    }

    public hasFileToUpload(): boolean {
        return !!this.file && !!this.fileData;
    }

    public getFile(): Blob {
        return this.file;
    }

    public setFileUrl(url: string): void {
        this.remove();
        this._safeFileUrl = null;
        this.fileUrl = url;
    }

    public getPublicPath(): SafeResourceUrl {
        if (!this.publicUrl && this.fileUrl) {
            this.publicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.path + this.fileUrl,
            );
        }
        if (this.document) {
            let url = `/documents/${this.document.id}/download?token=${this.accessToken}`;
            if (this.module === 'system') {
                url = `/system${url}`;
            }
            this.publicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `${environment.apiUrl}${url}`,
            );
            this.pathOut.emit(this.publicUrl);
        }
        return this.publicUrl;
    }

    public remove(): void {
        this.onTouched();
        this.file = null;
        this.fileUrl = null;
        this._safeFileUrl = null;
        this.fileData = null;
        if (this.fileElement) {
            this.fileElement.nativeElement.value = '';
        }
    }

    public emitDownload(event: Event): void {
        event.preventDefault();
        this.download.emit();
    }

    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(obj: Blob): void {
        this.file = obj;
    }

    private fileExceedsMaximum(fileSize: number): boolean {
        const maxBytes = this.maxMb * Math.pow(1024, 2);
        return fileSize > maxBytes;
    }
}
