import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mrc-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss'],
})
export class PagerComponent {
    @Output() public changeSize = new EventEmitter<number>();

    @Input()
    public get pageSize(): number | string {
        return this._pageSize;
    }
    public set pageSize(pageSize: number | string) {
        this._pageSize = pageSize === 999999 ? 'Alle' : pageSize;
    }
    private _pageSize: number | string = 20;

    public pager: (string | number)[] = [20, 50, 100, 150, 'Alle'];

    public changePageSize(): void {
        const size =
            this._pageSize === 'Alle' ? 999999 : Number(this._pageSize);
        this.changeSize.emit(size);
    }
}
