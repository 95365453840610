import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDropdownSettings, ListItem } from './multi-select.model';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true,
};
const noop = (): void => {
    return;
};

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent implements ControlValueAccessor {
    public _settings: IDropdownSettings;
    public _data: ListItem[] = [];
    public selectedItems: ListItem[] = [];
    public isDropdownOpen = true;
    public _placeholder = 'Select';
    private _sourceDataType = null;
    private _sourceDataFields: string[] = [];
    public filter: ListItem = new ListItem(this.data);
    private defaultSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'text',
        disabledField: 'isDisabled',
        enableCheckAll: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: false,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        itemsShowLimit: 999999999999,
        searchPlaceholderText: 'Search',
        noDataAvailablePlaceholderText: 'No data available',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false,
        allowRemoteDataSearch: false,
    };

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    @Input()
    public set placeholder(value: string) {
        if (value) {
            this._placeholder = value;
        } else {
            this._placeholder = 'Select';
        }
    }
    @Input()
    public disabled = false;

    @Input()
    public set settings(value: IDropdownSettings) {
        if (value) {
            this._settings = Object.assign(this.defaultSettings, value);
        } else {
            this._settings = Object.assign(this.defaultSettings);
        }
    }

    @Input()
    public set data(value: any[]) {
        if (!value || value.length == 0) {
            this._data = [];
        } else {
            const firstItem = value[0];
            this._sourceDataType = typeof firstItem;
            this._sourceDataFields = this.getFields(firstItem);
            this._data = value.map((item: any) =>
                typeof item === 'string' || typeof item === 'number'
                    ? new ListItem(item)
                    : new ListItem({
                          id: item[this._settings.idField],
                          text: item[this._settings.textField],
                          isDisabled: item[this._settings.disabledField],
                      }),
            );
        }
    }

    @Output() public filterChange = new EventEmitter<any>();

    @Output() public dropDownClose = new EventEmitter<any>();

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public select = new EventEmitter<any>();

    @Output() public deSelect = new EventEmitter<any>();

    @Output() public selectAll = new EventEmitter<any[]>();

    @Output() public deSelectAll = new EventEmitter<any[]>();

    constructor(private cdr: ChangeDetectorRef) {}

    public updateData(data): void {
        this.data = data;
    }

    public disable(): void {
        this.disabled = true;
    }

    public enable(): void {
        this.disabled = false;
    }

    public onFilterTextChange($event): void {
        this.filterChange.emit($event);
    }

    public onItemClick($event: any, item: ListItem): void | boolean {
        if (this.disabled || item.isDisabled) {
            return false;
        }

        const found = this.isSelected(item);
        const allowAdd =
            this._settings.limitSelection === -1 ||
            (this._settings.limitSelection > 0 &&
                this.selectedItems.length < this._settings.limitSelection);
        if (!found) {
            if (allowAdd) {
                this.addSelected(item);
            }
        } else {
            this.removeSelected(item);
        }
        if (
            this._settings.singleSelection &&
            this._settings.closeDropDownOnSelection
        ) {
            this.closeDropdown();
        }
    }

    public writeValue(value: any): void {
        if (value !== undefined && value !== null && value.length > 0) {
            if (this._settings.singleSelection) {
                try {
                    if (value.length >= 1) {
                        const firstItem = value[0];
                        this.selectedItems = [
                            typeof firstItem === 'string' ||
                            typeof firstItem === 'number'
                                ? new ListItem(firstItem)
                                : new ListItem({
                                      id: firstItem[this._settings.idField],
                                      text: firstItem[this._settings.textField],
                                      isDisabled:
                                          firstItem[
                                              this._settings.disabledField
                                          ],
                                  }),
                        ];
                    }
                } catch (e) {
                    return e.body.msg;
                }
            } else {
                const _data = value.map((item: any) =>
                    typeof item === 'string' || typeof item === 'number'
                        ? new ListItem(item)
                        : new ListItem({
                              id: item[this._settings.idField],
                              text: item[this._settings.textField],
                              isDisabled: item[this._settings.disabledField],
                          }),
                );
                if (this._settings.limitSelection > 0) {
                    this.selectedItems = _data.splice(
                        0,
                        this._settings.limitSelection,
                    );
                } else {
                    this.selectedItems = _data;
                }
            }
        } else {
            this.selectedItems = [];
        }
        this.onChangeCallback(value);
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    @HostListener('blur')
    public onTouched(): void {
        this.closeDropdown();
        this.onTouchedCallback();
    }

    public trackByFn(item: any): number | string {
        return item.id;
    }

    private isSelected(clickedItem: ListItem): boolean {
        let found = false;
        this.selectedItems.forEach((item) => {
            if (clickedItem.id === item.id) {
                found = true;
            }
        });
        return found;
    }

    public isLimitSelectionReached(): boolean {
        return this._settings.limitSelection === this.selectedItems.length;
    }

    public isAllItemsSelected(): boolean {
        const itemDisabledCount = this._data.filter(
            (item) => item.isDisabled,
        ).length;
        if (
            (!this.data || this.data.length === 0) &&
            this._settings.allowRemoteDataSearch
        ) {
            return false;
        }
        return (
            this._data.length === this.selectedItems.length + itemDisabledCount
        );
    }

    public showButton(): boolean {
        if (!this._settings.singleSelection) {
            return this._settings.limitSelection <= 0;
        } else {
            return false;
        }
    }

    public itemShowRemaining(): number {
        return this.selectedItems.length - this._settings.itemsShowLimit;
    }

    public addSelected(item: ListItem): void {
        if (this._settings.singleSelection) {
            this.selectedItems = [];
            this.selectedItems.push(item);
        } else {
            this.selectedItems.push(item);
        }
        this.onChangeCallback(this.emittedValue(this.selectedItems));
        this.select.emit(this.emittedValue(item));
    }

    public removeSelected(itemSel: ListItem): void {
        this.selectedItems.forEach((item) => {
            if (itemSel.id === item.id) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        });
        this.onChangeCallback(this.emittedValue(this.selectedItems));
        this.deSelect.emit(this.emittedValue(itemSel));
    }

    public emittedValue(val: any): any {
        const selected = [];
        if (Array.isArray(val)) {
            val.forEach((item) => {
                selected.push(this.objectify(item));
            });
        } else {
            if (val) {
                return this.objectify(val);
            }
        }
        return selected;
    }

    public objectify(val: ListItem): any {
        if (this._sourceDataType === 'object') {
            const obj = {};
            obj[this._settings.idField] = val.id;
            obj[this._settings.textField] = val.text;
            if (this._sourceDataFields.includes(this._settings.disabledField)) {
                obj[this._settings.disabledField] = val.isDisabled;
            }
            return obj;
        }
        if (this._sourceDataType === 'number') {
            return Number(val.id);
        } else {
            return val.text;
        }
    }

    public toggleDropdown(evt): void {
        evt.preventDefault();
        if (this.disabled && this._settings.singleSelection) {
            return;
        }
        this._settings.defaultOpen = !this._settings.defaultOpen;
        if (!this._settings.defaultOpen) {
            this.dropDownClose.emit();
        }
    }

    public closeDropdown(): void {
        this._settings.defaultOpen = false;
        if (this._settings.clearSearchFilter) {
            this.filter.text = '';
        }
        this.dropDownClose.emit();
    }

    public toggleSelectAll(): void | boolean {
        if (this.disabled) {
            return false;
        }
        if (!this.isAllItemsSelected()) {
            this.selectedItems = this._data
                .filter((item) => !item.isDisabled)
                .slice();
            this.selectAll.emit(this.emittedValue(this.selectedItems));
        } else {
            this.selectedItems = [];
            this.deSelectAll.emit(this.emittedValue(this.selectedItems));
        }
        this.onChangeCallback(this.emittedValue(this.selectedItems));
    }

    public getFields(inputData): any[] {
        const fields = [];
        if (typeof inputData !== 'object') {
            return fields;
        }
        for (const prop in inputData) {
            fields.push(prop);
        }
        return fields;
    }
}
