import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    title?: string;
    content: string;
    contentClass?: string;
    rejectBtnLabel?: string;
    rejectBtnClass?: string;
    acceptBtnLabel?: string;
    acceptBtnClass?: string;
}

export interface CloseDialogData {
    confirmed: boolean;
}

@Component({
    selector: 'mrc-confirm-modal-material',
    templateUrl: './confirm-modal-material.component.html',
})
export class ConfirmModalMaterialComponent {
    private destroyRef = inject(DestroyRef);

    constructor(
        public dialogRef: MatDialogRef<
            ConfirmModalMaterialComponent,
            CloseDialogData
        >,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.dialogRef
            .backdropClick()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.reject();
            });
    }

    public accept(): void {
        this.dialogRef.close({ confirmed: true });
    }

    public reject(): void {
        this.dialogRef.close({ confirmed: false });
    }
}
