<div class="image-uploader">
    <ng-container *ngIf="imageUrl; else upload">
        <div class="image-container">
            <dd-image
                [src]="imageUrl"
                [alt]="alt"
            >
            </dd-image>

            <div class="remove-backdrop">
                <button
                    type="button"
                    class="icon-container"
                    (click)="remove()"
                >
                    <i
                        aria-hidden="true"
                        class="fal fa-trash"
                    ></i>
                </button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #upload>
    <div class="w-100">
        <dd-file-upload
            [formFieldId]="formFieldId"
            [config]="{
                text: 'Logo hochladen',
                acceptedFileTypes: [FileType.PNG, FileType.JPEG]
            }"
            (handleFileInput)="load($event)"
        ></dd-file-upload>
    </div>
</ng-template>
